import React, {useCallback, useEffect} from "react";
import {Button, Form, Input} from "semantic-ui-react";
import {useForm} from "react-hook-form";
import {usersActions} from "../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {APP} from "../../../constants";
import Swal from "sweetalert2";

const UsersForm = () => {
    const dispatch = useDispatch();
    const save = useSelector(({Users}) => Users.save);
    const entity = useSelector(({Users}) => Users.entity);

    const {register, handleSubmit, setValue, formState, trigger, reset} = useForm({
        mode: 'onChange',
        defaultValues: {
            id: entity.id || "",
            email: entity.email || "",
            name: entity.name || "",
            password: "",
            phone: entity.phone || "",
            cellphone: entity.cellphone || "",
            active: entity.active || "1",
            upload_driver: entity.upload_driver || "publit.io",
            upload_key: entity.upload_key || "1",
            upload_secret: entity.upload_secret || "1",
        }
    });

    const closeModal = useCallback(() => {
        dispatch(usersActions.toggle(false));
    }, [dispatch]);

    useEffect(() => {
        if (save.success && !save.loading) {
            async function call() {
                await reset();
                await closeModal();
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Cliente ${entity.id ? 'editado' : 'adicionado'} com sucesso!`,
                    showConfirmButton: false,
                    timer: 2000,
                    backdrop: false,
                    toast: true
                });
            }

            call();
        }
    }, [save, entity, reset, closeModal]);

    useEffect(() => {
        register('name', {required: true});
        register('email', {required: true});
        register('password');
        register('phone');
        register('cellphone');
        register('active');
        register('upload_driver');
        register('upload_key');
        register('upload_secret');
    }, [register]);

    const onSubmit = (data) => {

        console.log(data);

        if(data.password.length === 0){
            delete data.password;
        }

        if (data.id) {
            dispatch(usersActions._put(data));
        } else {
            dispatch(usersActions._post(data));
        }
    };

    const {isValid} = formState;

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            loading={save.loading}
        >
            <input type={'hidden'} name={'id'} defaultValue={entity.id || ""} ref={register()}/>
            <input type={'hidden'} name={'upload_driver'} defaultValue={entity.upload_driver || "publit.io"} ref={register()}/>

            <Form.Field
                name={"name"}
                label={"Nome"}
                onChange={async (e, {name, value}) => {
                    await setValue(name, value);
                    await trigger(name);
                }}
                defaultValue={entity.name || ""}
                control={Input}
                autoFocus
            />
            <Form.Group>
                <Form.Field
                    name={"email"}
                    label={"E-mail"}
                    onChange={async (e, {name, value}) => {
                        await setValue(name, value);
                        await trigger(name);
                    }}
                    defaultValue={entity.email || ""}
                    control={Input}
                    width={11}
                />
                <Form.Field
                    type={"password"}
                    name={"password"}
                    label={"Senha"}
                    onChange={async (e, {name, value}) => {
                        await setValue(name, value);
                        await trigger(name);
                    }}
                    control={Input}
                    width={5}
                />
            </Form.Group>
            <Form.Group>
                <Form.Field
                    name={"phone"}
                    label={"Telefone fixo"}
                    onChange={async (e, {name, value}) => {
                        await setValue(name, value);
                        await trigger(name);
                    }}
                    defaultValue={entity.phone || ""}
                    control={Input}
                    width={6}
                />
                <Form.Field
                    name={"cellphone"}
                    label={"Telefone Celular"}
                    onChange={async (e, {name, value}) => {
                        await setValue(name, value);
                        await trigger(name);
                    }}
                    defaultValue={entity.cellphone || ""}
                    control={Input}
                    width={6}
                />
                <Form.Field
                    width={4}

                        fluid
                        name={'active'}
                        label={'Ativo'}
                        options={APP.options.sn}
                        defaultValue={entity.id ? (entity.active ? '1' : '0') : '1'}
                        onChange={async (e, {name, value}) => {
                            await setValue('active', value);
                            await trigger(name);
                        }}
                        control={Form.Select}

                />
            </Form.Group>

                {/*<Form.Field*/}
                {/*    name={"upload_key"}*/}
                {/*    label={"API Key"}*/}
                {/*    onChange={async (e, {name, value}) => {*/}
                {/*        await setValue(name, value);*/}
                {/*        await trigger(name);*/}
                {/*    }}*/}
                {/*    defaultValue={entity.upload_key || ""}*/}
                {/*    control={Input}*/}
                {/*/>*/}
                {/*<Form.Field*/}
                {/*    name={"upload_secret"}*/}
                {/*    label={"API Secret"}*/}
                {/*    onChange={async (e, {name, value}) => {*/}
                {/*        await setValue(name, value);*/}
                {/*        await trigger(name);*/}
                {/*    }}*/}
                {/*    defaultValue={entity.upload_secret || ""}*/}
                {/*    control={Input}*/}
                {/*/>*/}

            <div
                style={{textAlign: "right"}}
            >
                <Button
                    basic
                    type={"button"}
                    onClick={() => {
                        reset();
                        closeModal();
                    }}
                >
                    Fechar
                </Button>
                <Button
                    primary
                    type={"submit"}
                    disabled={!isValid}
                >
                    Salvar
                </Button>
            </div>
        </Form>
    );
}

export default UsersForm;