import api from "../../services/api"
import {MUSICS} from "../../constants";

export const musicsActions = {
    _get: getMusics,
    _post: postMusics,
    _put: putMusics,
    _delete: deleteMusics,
    uploadMusic,
    entity,
    toggle,
    play
};

function getMusics(params = {}) {

    const storage = `MUSICS_${params.playlist_id}`;

    return (dispatch) => {

        // console.log('getMusics', typeof(params.online));

        // load from local
        if(typeof(params.online)==="boolean" && !params.online){
            let cache = localStorage.getItem(storage) || false
            if(cache){
                dispatch({
                    type: MUSICS.LIST_SUCCESS,
                    payload: JSON.parse(cache),
                });
            }

            return;
        }

        // loading
        dispatch(loading(MUSICS.LIST));

        // get data
        api
            .get('/musics?sort=name', {params})
            .then((res) => {

                localStorage.setItem(storage, JSON.stringify(res.data));

                dispatch({
                    type: MUSICS.LIST_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                dispatch({
                    type: MUSICS.LIST_FAILURE,
                    payload: e,
                })
            });
    }
}

async function uploadMusic(params = {}, data = {}, progress) {
    return new Promise(function (resolve, reject) {
        api
            .post('https://api.publit.io/v1/files/create', data, {
                params,
                onUploadProgress: function (progressEvent) {
                    progress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                }
            })
            .then(({data}) => resolve(data))
            .catch(e => reject(e))
    });
}

async function postMusics(data = {}, progress) {
    return async (dispatch) => {
        // get index
        const index = data.index;

        // loading
        await dispatch(loading(MUSICS.CREATE));

        // get data
        return await api
            .post('/musics', data, {
                onUploadProgress: function (progressEvent) {
                    progress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                }
            })
            // .then((res) => {
            //     dispatch({
            //         type: MUSICS.CREATE_SUCCESS,
            //         payload: {...res.data, index},
            //     });
            // })
            .catch((e) => {
                dispatch({
                    type: MUSICS.CREATE_FAILURE,
                    payload: {...e, index},
                });
                throw e;
            });
    }
}

function putMusics(data = {}) {
    return (dispatch) => {
        // loading
        dispatch(loading(MUSICS.UPDATE));

        // get data
        api
            .put(`/musics/${data.id}`, data)
            .then((res) => {
                dispatch({
                    type: MUSICS.UPDATE_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                dispatch({
                    type: MUSICS.UPDATE_FAILURE,
                    payload: e,
                })
            });
    }
}

function deleteMusics(id) {
    return (dispatch) => {
        // loading
        dispatch(loading(MUSICS.DELETE));

        // get data
        api
            .delete(`/musics/${id}`)
            .then((res) => {
                dispatch({
                    type: MUSICS.DELETE_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                dispatch({
                    type: MUSICS.DELETE_FAILURE,
                    payload: e,
                })
            });
    }
}

function loading(type, loading = true) {
    return {type, payload: loading};
}

function entity(payload) {
    return {type: MUSICS.ENTITY, payload};
}

function play(index) {
    return {type: MUSICS.PLAY, payload: {index}};
}

function toggle(showModal, action = "", entity = {}) {
    return {type: MUSICS.CRUD, payload: {showModal, action, entity}};
}