import React, {Fragment, useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {usersActions} from "../../redux/actions";
import {MainContainer, MusicContainer, MusicsContainer} from "./style";
import {Button, Icon} from "semantic-ui-react";
import {useHistory} from "react-router-dom";
import {Title} from "./components/Title";

import Empty from "../Empty/Empty";
import UsersModal from "../../screens/admin/Users/UsersModal";
import Swal from "sweetalert2";

const User = ({user, selectUser}) => {
    const dispatch = useDispatch();

    return (
        <MusicContainer
            onClick={() => selectUser(user.id)}
        >
            <div>
                <Icon
                    name={"user"}
                />
            </div>
            <div>
                <div>{user.name}</div>
                <div>
                    <span
                        style={{
                            width: "60px",
                            display: "inline-flex",
                        }}
                    >
                        <Icon
                            name={"list"}
                        />{user.playlist_count}
                    </span>
                    <span
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            selectUser(user.id, "spots")
                        }}
                    >
                        <Icon
                            name={"announcement"}
                        />{user.spot_count || 0}
                    </span>
                </div>
            </div>
            <div>
                {user.active ? 'Ativo' : 'Inativo'}
            </div>
            <div>
                <Button.Group
                    icon
                    vertical
                    inverted
                    basic
                    size={"tiny"}
                >
                    <Button
                        icon={"pencil"}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            dispatch(usersActions.toggle(true, "edit", user));
                        }}
                    />
                    <Button
                        icon={"trash"}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            dispatch(usersActions.toggle(true, "delete", user));
                        }}
                    />
                </Button.Group>
            </div>
        </MusicContainer>
    );
}

const Main = ({users}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const createUser = () => {
        dispatch(usersActions.toggle(true, "create"));
    }

    const selectUser = (id, type = "playlists") => {
        history.push(`/client/home/${id}/${type}`);
    }

    return (
        <Fragment>
            <div id={"playlistScroll"}>
                {
                    users.data.length
                        ? <MusicsContainer>
                            {
                                users.data.map((user) => {
                                    return (
                                        <User
                                            key={`user-${user.id}`}
                                            // role={role}
                                            user={user}
                                            selectUser={selectUser}
                                            // selectPlaylist={selectPlaylist}
                                            // editPlaylist={editPlaylist}
                                            // deletePlaylist={deletePlaylist}
                                        />
                                    )
                                })
                            }
                        </MusicsContainer>
                        : <Empty
                            icon={"user"}
                            title={"Nenhuma cliente encontrado"}
                            message={"Começe agora mesmo adicionando um novo Cliente"}
                        />
                }
            </div>
            <div id={"playlistActions"}>
                <UsersModal/>
                <Button
                    fluid
                    basic
                    inverted
                    content={"ADICIONAR CLIENTE"}
                    onClick={() => createUser()}
                />
            </div>
        </Fragment>
    );
}

const Users = () => {
    const dispatch = useDispatch();

    const users = useSelector(({Users}) => Users.results);
    const save = useSelector(({Users}) => Users.save);
    const crud = useSelector(({Users}) => Users.crud);
    const entity = useSelector(({Users}) => Users.entity);

    const getUsers = useCallback(() => {
        dispatch(usersActions._get({active: 1, role: 'user', sort: 'name'}));
    }, [dispatch]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    useEffect(() => {
        if (save.success) {
            getUsers();
        }
    }, [save, getUsers]);

    useEffect(() => {
        if (crud.showModal && crud.action === "delete") {
            Swal.fire({
                title: 'Atenção!',
                text: 'Deseja realmente excluir este cliente?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Sim, quero excluir!',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(usersActions._delete(entity.id));
                } else {
                    dispatch(usersActions.toggle(false));
                }
            });
        }
    }, [dispatch, entity, crud]);

    return (
        <MainContainer>
            <Title
                loading={users.loading}
                counter={users.data.length}
                duration={0}
                title={"Clientes"}
            />
            <Main
                users={users}
            />
        </MainContainer>
    );
}

export default Users;