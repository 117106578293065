import api from "../../services/api"
import {MUSICS, SPOTS} from "../../constants";

export const spotsActions = {
    _get: getSpots,
    _post: postSpot,
    _put: putSpot,
    _delete: deleteSpot,
    uploadMusic,
    entity,
    toggle,
};

async function uploadMusic(params = {}, data = {}) {
    return new Promise(function (resolve, reject) {
        api
            .post('https://api.publit.io/v1/files/create', data, {params})
            .then(({data}) => resolve(data))
            .catch(e => reject(e))
    });
}

function getSpots(params = {}) {

    const storage = `SPOTS_${params.user_id}`;

    return (dispatch) => {
        // load from local
        let cache = localStorage.getItem(storage) || false;
        if(cache){
            dispatch({
                type: SPOTS.LIST_SUCCESS,
                payload: JSON.parse(cache),
            });
        }

        // loading
        dispatch(loading(SPOTS.LIST));

        // get data
        api
            .get('/spots', {params})
            .then((res) => {

                localStorage.setItem(storage, JSON.stringify(res.data));

                dispatch({
                    type: SPOTS.LIST_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                dispatch({
                    type: SPOTS.LIST_FAILURE,
                    payload: e,
                })
            });
    }
}

function postSpot(data = {}){
    return (dispatch) => {
        // loading
        dispatch(loading(SPOTS.CREATE));

        // get data
        api
            .post('/spots', data)
            .then((res) => {
                dispatch({
                    type: SPOTS.CREATE_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                dispatch({
                    type: SPOTS.CREATE_FAILURE,
                    payload: e,
                })
            });
    }
}

function putSpot(data = {}){
    return (dispatch) => {
        // loading
        dispatch(loading(SPOTS.UPDATE));

        // get data
        api
            .put(`/spots/${data.id}`, data)
            .then((res) => {
                dispatch({
                    type: SPOTS.UPDATE_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                dispatch({
                    type: SPOTS.UPDATE_FAILURE,
                    payload: e,
                })
            });
    }
}

function deleteSpot(id){
    return (dispatch) => {
        // loading
        dispatch(loading(SPOTS.DELETE));

        // get data
        api
            .delete(`/spots/${id}`)
            .then((res) => {
                dispatch({
                    type: SPOTS.DELETE_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                dispatch({
                    type: SPOTS.DELETE_FAILURE,
                    payload: e,
                })
            });
    }
}

function loading(type, loading = true) {
    return {type, payload: loading};
}

function entity(payload){
    return {type: SPOTS.ENTITY, payload};
}

function toggle(showModal, action = "", entity = {}) {
    return {type: SPOTS.CRUD, payload: {showModal, action, entity}};
}