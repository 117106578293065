import React, {useContext, useState} from "react";
import {Button, Form, Header, Image, Message} from "semantic-ui-react";
import {useForm} from "react-hook-form";
import {useSearchParam} from "react-use";

import {version} from "../../../../package.json";

import MainContext from "../../../contexts/MainContext";

import {usersGetToken} from "../../../actions/";

import {WrapperCheck, Wrapper, WrapperLogin, WrapperLogo, WrapperRecover} from './style';

const Login = () => {
    const auth = useSearchParam("auth");
    const {login} = useContext(MainContext);
    const [showMessage, setShowMessage] = useState(false);
    const {register, handleSubmit, formState} = useForm({
        mode: 'onChange',
        defaultValues: {
            email: '',
            password: '',
            // remember: true
        }
    });

    React.useEffect(() => {
        if(auth === '401') {
            alert('Você foi desconectado. Suas credenciais foram utilizadas em outro aparelho. Em caso de dúvidas, entre em contato com o suporte técnico.');
        }
    }, [auth]);

    // React.useEffect(() => {
    //     register('email', {
    //         required: true,
    //         // pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    //     });
    //
    //     register('password', {
    //         required: true,
    //         minLength: 4
    //     });
    //
    //     register('remember');
    // }, [register])

    const onSubmit = async (data) => {

        setShowMessage(false);

        try {
            const res = await usersGetToken(data);
            const ret = res.data;

            // save on local storage
            if (ret.success) {
                login(ret.data);
            }

        } catch (e) {
            setShowMessage(true);
        }
    };

    const {isValid, isSubmitting} = formState;

    return (
        <Wrapper>
            <WrapperLogo>
                <Image
                    src={"/images/login/logo.png"}
                    width={180}
                />
            </WrapperLogo>
            <WrapperLogin>
                {
                    showMessage && <Message
                        error
                        content={"E-mail ou senha inválidos, tente novamente!"}
                        style={{
                            textAlign: "center"
                        }}
                    />
                }
                <Header
                    textAlign={"center"}
                    content={"ACESSE SUA CONTA"}
                />
                <Form
                    id={'form-login'}
                    onSubmit={handleSubmit(onSubmit)}
                    loading={isSubmitting}
                >
                    <Form.Field>
                        <label>E-mail</label>
                        <input
                            name={"email"}
                            // icon={"at"}
                            // iconPosition={"left"}
                            // placeholder={"E-mail"}
                            ref={register({
                                required: true,
                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                            // onChange={async (e, {name, value}) => {
                            //     setValue(name, value);
                            //     await trigger(name);
                            // }}
                            autoFocus
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Senha</label>
                        <input
                            type={"password"}
                            name={"password"}
                            // icon={"lock"}
                            // iconPosition={"left"}
                            ref={register({required: true})}
                            // placeholder={"Senha"}
                            // onChange={async (e, {name, value}) => {
                            //     setValue(name, value);
                            //     await trigger(name);
                            // }}
                        />
                    </Form.Field>
                    <Button
                        fluid
                        type='submit'
                        disabled={!isValid || isSubmitting}
                        // loading={isSubmitting}
                        color={"black"}
                        content={'ENTRAR'}
                    />
                    <WrapperRecover>
                        <div>
                            {/*<Checkbox*/}
                            {/*    label={"Lembrar-me"}*/}
                            {/*    name={"remember"}*/}
                            {/*    // checked={formState.}*/}
                            {/*    disabled={true}*/}
                            {/*    onChange={async (e, {name, checked}) => {*/}
                            {/*        setValue(name, checked);*/}
                            {/*        await trigger(name);*/}
                            {/*    }}*/}
                            {/*/>*/}
                        </div>
                        <div>
                            {/*<NavLink to={"/users/recover"}>Esqueci minha senha</NavLink>*/}
                            <a href={"#"}>Esqueci minha senha</a>
                        </div>
                    </WrapperRecover>
                </Form>
            </WrapperLogin>
            <WrapperCheck>
                v{version}
            </WrapperCheck>
        </Wrapper>
    );
}

export default Login;