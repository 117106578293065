import axios from "axios";

const api = axios.create({
    baseURL: 'https://api.radiointeligente.com.br', // 'http://localhost:8081', //
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
    },
});

// apply token
api.interceptors.request.use(async (config) => {
    // get user
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    // check
    if (user.token) {
        config.headers.Authorization = `Bearer ${user.token}`
    }

    // return config
    return config
});

// apply token
api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // get user
        const user = JSON.parse(localStorage.getItem('user') || '{}');

        if (user.token && error.response.status === 401) {
            const requestConfig = error.config;

            localStorage.removeItem('user');

            window.location.href = '/signin?auth=401';

            return axios(requestConfig);
        }

        return Promise.reject(error);
    }
);

export default api;