import React, {useContext} from 'react';
import PublicRoutes from "../screens/public/PublicRoutes";
import ClientRoutes from "../screens/client/ClientRoutes";

import MainContext from "../contexts/MainContext";

const Routes = () => {

    const {signed} = useContext(MainContext);

    return signed.id ? <ClientRoutes/> : <PublicRoutes/>;
}

export default Routes;
