export const MUSICS = {
  ENTITY: "MUSICS_ENTITY",
  CRUD: "MUSICS_CRUD",

  LIST: "MUSICS_LIST",
  LIST_SUCCESS: "MUSICS_LIST_SUCCESS",
  LIST_FAILURE: "MUSICS_LIST_FAILURE",

  CREATE: "MUSICS_CREATE",
  CREATE_SUCCESS: "MUSICS_CREATE_SUCCESS",
  CREATE_FAILURE: "MUSICS_CREATE_FAILURE",

  READ: "MUSICS_READ",
  READ_SUCCESS: "MUSICS_READ_SUCCESS",
  READ_FAILURE: "MUSICS_READ_FAILURE",

  UPDATE: "MUSICS_UPDATE",
  UPDATE_SUCCESS: "MUSICS_UPDATE_SUCCESS",
  UPDATE_FAILURE: "MUSICS_UPDATE_FAILURE",

  DELETE: "MUSICS_DELETE",
  DELETE_SUCCESS: "MUSICS_DELETE_SUCCESS",
  DELETE_FAILURE: "MUSICS_DELETE_FAILURE",

  PLAY: "MUSIC_PLAY",
  PAUSE: "MUSIC_PAUSE",
  PREV: "MUSIC_PREV",
  NEXT: "MUSIC_NEXT",
};