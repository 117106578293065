import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Login from './Login/Login';
import Check from './Check';
import NoMatch from "../Errors/404";

function PublicRoutes() {
    return (
        <Switch>
            <Route path="/signin" exact component={Login} />
            <Route path="/check" exact component={Check} />
            {/*<Route path="/signup/:token" component={Signup} />*/}
            {/*<Route path="*" exact component={Login} />*/}
            <Route component={Login}/>
        </Switch>
    );
}

export default PublicRoutes;
