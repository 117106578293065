import {APP, USERS} from "../../constants";

const start = APP.defaultState;

export default function reducer(state = start, action) {

    const results = state.results;

    switch (action.type) {
        case USERS.ENTITY:
            return {...state, entity: {...start.entity, ...action.payload}};

        case USERS.CRUD:
            return {
                ...state,
                crud: {...start.crud, ...action.payload},
                entity: action.payload.entity,
                save: start.save,
            };

        case USERS.LIST:
            return {...state, results: {...results, loading: true}};

        case USERS.LIST_SUCCESS:
            return {...state, results: {...start.results, ...action.payload}};

        case USERS.LIST_FAILURE:
            return {...state, results: {...results, loading: false, error: action.payload}};

        case USERS.CREATE:
        case USERS.UPDATE:
        case USERS.DELETE:
            return {...state, save: {...start.save, loading: true}};

        case USERS.CREATE_SUCCESS:
        case USERS.UPDATE_SUCCESS:
        case USERS.DELETE_SUCCESS:
            return {...state, save: {...start.save, ...action.payload}};

        case USERS.CREATE_FAILURE:
        case USERS.UPDATE_FAILURE:
        case USERS.DELETE_FAILURE:
            return {...state, save: {...start.save, error: action.payload}};

        default:
            return state;
    }
}
