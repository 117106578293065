import styled from "styled-components";
import {Container, Segment} from "semantic-ui-react";

export const WrappedSegment = styled(Segment)`
    > button.ui.basic.inverted.button {
        margin-right: 1em;
        
        :hover{
            background-color: rgba(255, 255, 255, .2) !important;
        }
        
        :not(.large) {
            box-shadow: none !important;
        }
    }
    
    > button.ui.active.basic.inverted.button {
        background-color: rgba(255, 255, 255, .2) !important;
    }

    > button.ui.large.basic.inverted.button {
        box-shadow: 0 0 0 1px rgba(255, 255, 255, .5) inset!important;
        padding: 16px !important;
    }
`;

export const ProgressContainer = styled(Container)`
    display: flex !important;
    align-items: center;
    margin-top: 1rem;
    .player-progress-time{
        font-size: 12px;
    }
    .player-progress-bar {
        flex-grow: 1;
        margin-left: 1rem;
        margin-right: 1rem;
        div {
            margin-bottom: 0 !important;
            min-width: inherit !important;
        }
    }
`;

export const Version = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: -10px;
    color: rgba(255, 255, 255, .4);
    font-size: 11px;
`