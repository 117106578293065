import React, {createRef, useEffect, useState} from "react";
import {Button, Container, Icon, Image, Segment} from "semantic-ui-react";
import {Wrapper} from "./style";
import {addToCache, getCacheKeys} from "../../../utils";
import {NavLink} from "react-router-dom";
import { initDB, useIndexedDB } from 'react-indexed-db';

initDB({
    name: 'radio',
    version: 1,
    objectStoresMeta: [{
        store: 'musics',
        storeConfig: {},
        storeSchema: []
    }]
});

const audio = createRef();
const blobRef = createRef();

const setFile = (putFile) => {

        // Create XHR
        const xhr = new XMLHttpRequest();

        xhr.open("GET", "/test.mp3", true);
        // Set the responseType to blob
        xhr.responseType = "blob";

        xhr.addEventListener("load", function () {
            if (xhr.status === 200) {
                console.log("Image retrieved");

                // Blob as response
                const blob = xhr.response;
                console.log("Blob:" + blob);

                // Put the received blob into IndexedDB
                // putElephantInDb(blob);
                putFile(blob, "/test.mp3");
            }
        }, false);

        // Send XHR
        xhr.send();

}

const Check = () => {
    const [errors, setErrors] = useState([]);
    const [status, setStatus] = useState("play")
    const [statusBlob, setStatusBlob] = useState("play")
    const [keys, setKeys] = useState([]);
    const [files, setFiles] = useState([]);
    const [src, setSrc] = useState("/test.mp3");
    const [musics, setMusics] = useState()
    const {getAll, add} = useIndexedDB('musics');

    useEffect(() => {
        getAll().then(musics => {
            const URL = window.URL || window.webkitURL;
            let all = [];
            for(const music of musics){
                // Create and revoke ObjectURL
                const src = URL.createObjectURL(music);
                all.push({src})
            }
            setMusics(all);
        });
    }, []);

    useEffect(() => {

        // db();

        async function getServiceWorker() {
            let name = "Service worker";
            let status = "ERROR";
            if ('ServiceWorker' in window) {
                status = "OK";
            }

            await setErrors((prev) => [...prev, {name, status}]);
        }

        async function getCaches() {
            let name = "Cache enable";
            let status = "ERROR";
            if ('caches' in window) {
                status = "OK";
            }

            await setErrors((prev) => [...prev, {name, status}]);
        }

        async function getKeys() {
            let name = "Get cache keys"
            let status = "ERROR";
            let details = "";
            try {
                // get keys
                const res = await getCacheKeys();

                // set keys
                setKeys(res);

                // set msg
                status = "OK";
                details = res.join(" | ");
            } catch (e) {
                details = e.getMessage();
            }finally {
                await setErrors((prev) => [...prev, {name, status, details}]);
            }
        }

        async function getFile(){
            let name = "Add file to cache"
            let status = "ERROR";
            let details = "";
            try {
                console.log('using fetch');

                // await fetch('/test.mp3');
                // await fetch('https://files.radiointeligente.com.br/musics/N60454e95d5d5f.mp3');
                await addToCache('musics', '/test.mp3');
                await addToCache('musics', 'https://files.radiointeligente.com.br/musics/N60454e95d5d5f.mp3');

                status = "OK";
            }catch (e) {
                details = e.getMessage();
            }finally {
                await setErrors((prev) => [...prev, {name, status, details}]);
            }
        }

        async function checkFile(){
            let name = "Get cache file"
            let status = "ERROR";
            let details = "";

            try {
                const res = await fetch('/test.mp3', {cache: "force-cache"});
                status = "OK";
                details = res.status;
            }catch (e) {
                details = e.getMessage();
            }finally {
                await setErrors((prev) => [...prev, {name, status, details}]);
            }
        }

        async function getCacheFiles(){
            try {
                const myCache = await caches.open('musics');
                const files = await myCache.keys();

                setFiles(files);
            }catch (e) {
                let name = "Get cache files"
                let status = "ERROR";
                let details = e.getMessage();
                await setErrors((prev) => [...prev, {name, status, details}]);
            }
        }

        setErrors([]);
        getServiceWorker();
        getCaches();
        getKeys();
        getFile();
        checkFile();
        getCacheFiles();
    }, [setErrors]);

    useEffect(() => {
        console.log(audio.current.paused);
        if(status === "pause"){
            audio.current.currentTime = 0;
            audio.current.play();
        }else{
            audio.current.pause();
        }

    }, [status]);

    useEffect(() => {
        if(!musics || !musics[0]){
            return;
        }

        if(statusBlob === "pause"){
            blobRef.current.currentTime = 0;
            blobRef.current.play();
        }else{
            blobRef.current.pause();
        }

    }, [statusBlob]);

    return (
        <Wrapper>
            <Image
                src={"/images/logo.svg"} width={"128"}
            />
            <audio src={src} ref={audio}></audio>
            <Container text>
                <Segment.Group>
                    {
                        errors.map((error, i) => {
                            return <Segment
                                key={`error-${i}`}
                                color={error.status === "OK" ? "green" : "red"}
                            >
                                {error.name}: {error.status} {error.details || ""}
                            </Segment>
                        })
                    }
                    <Segment>
                        Musics in cache
                        <ul>
                            {
                                files.map((file, i) => {
                                    return <li
                                        key={`file-${i}`}
                                    >
                                        <a href={"#"} onClick={() => setSrc(file.url)}>{file.url}</a>
                                    </li>
                                })
                            }
                        </ul>
                    </Segment>
                </Segment.Group>
                <Segment textAlign={"center"}>
                    CacheAPI
                    <Icon
                        name={status}
                        onClick={() => setStatus(status === "pause" ? "play" : "pause")}
                    />
                </Segment>
                <Segment>
                    IndexdDB
                    <Button
                        onClick={() => {
                            setFile(add);
                        } }
                        content={"Set file"}
                    />
                    <Button
                        primary
                        onClick={() => {
                            setFile(musics.add);
                            // musics.add("asdasdasd", "/teste.mp3");
                        } }
                        content={"Get file"}
                    />
                    <pre>
                        {JSON.stringify(musics)}
                    </pre>
                    {
                        (musics && musics[0]) &&<audio src={musics[0]['src']} ref={blobRef}/>
                    }
                    <Icon
                        name={statusBlob}
                        onClick={() => setStatusBlob(statusBlob === "pause" ? "play" : "pause")}
                    />
                </Segment>
                <NavLink to={"/login"}>Voltar</NavLink>
            </Container>
        </Wrapper>
    );
}

export default Check;