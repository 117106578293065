export const SPOTS = {
  ENTITY: "SPOTS_ENTITY",
  CRUD: "SPOTS_CRUD",

  LIST: "SPOTS_LIST",
  LIST_SUCCESS: "SPOTS_LIST_SUCCESS",
  LIST_FAILURE: "SPOTS_LIST_FAILURE",

  CREATE: "SPOTS_CREATE",
  CREATE_SUCCESS: "SPOTS_CREATE_SUCCESS",
  CREATE_FAILURE: "SPOTS_CREATE_FAILURE",

  READ: "SPOTS_READ",
  READ_SUCCESS: "SPOTS_READ_SUCCESS",
  READ_FAILURE: "SPOTS_READ_FAILURE",

  UPDATE: "SPOTS_UPDATE",
  UPDATE_SUCCESS: "SPOTS_UPDATE_SUCCESS",
  UPDATE_FAILURE: "SPOTS_UPDATE_FAILURE",

  DELETE: "SPOTS_DELETE",
  DELETE_SUCCESS: "SPOTS_DELETE_SUCCESS",
  DELETE_FAILURE: "SPOTS_DELETE_FAILURE",
};