export const USERS = {
  ENTITY: "USERS_ENTITY",
  CRUD: "USERS_CRUD",

  LIST: "USERS_LIST",
  LIST_SUCCESS: "USERS_LIST_SUCCESS",
  LIST_FAILURE: "USERS_LIST_FAILURE",

  CREATE: "USERS_CREATE",
  CREATE_SUCCESS: "USERS_CREATE_SUCCESS",
  CREATE_FAILURE: "USERS_CREATE_FAILURE",

  READ: "USERS_READ",
  READ_SUCCESS: "USERS_READ_SUCCESS",
  READ_FAILURE: "USERS_READ_FAILURE",

  UPDATE: "USERS_UPDATE",
  UPDATE_SUCCESS: "USERS_UPDATE_SUCCESS",
  UPDATE_FAILURE: "USERS_UPDATE_FAILURE",

  DELETE: "USERS_DELETE",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",
};