import styled from "styled-components";

export const Wrapper = styled.div`
    background-color: #711A1D;
    height: 100%;
`;

export const WrapperLogo = styled.div`
    // background-color: #1E2126;
    margin: 0 auto;
    width: fit-content;
`;

export const WrapperLogin = styled.div`
    background-color: #FFF;
    margin: 0 auto;
    width: 90%;
    max-width: 440px;
    border: 1px solid rgba(0, 0, 0, .4);
    margin-top: 1.5rem;
    padding: 2rem;
    border-radius: 4px;
`;

export const WrapperRecover = styled.div`
  display: flex;
  margin-top: 1.5rem;
  > div:first-child {
    flex-grow: 1;
  }
`;

export const WrapperCheck = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    padding: .5rem;
    color: rgba(255, 255, 255, .8);
    a {
        color: rgba(255, 255, 255, .6) !important;
    }
`