import React, {Fragment} from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {BrowserRouter} from "react-router-dom";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import {version} from "../package.json";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import store from "./redux/store"
import Routes from "./routes";

import {MainProvider} from "./contexts/MainContext";

import 'semantic-ui-css/semantic.min.css';
import 'sweetalert2/dist/sweetalert2.css';
import './index.css';

Sentry.init({
    dsn: "https://5645ed22abc143288523565435c8f3c2@o1097310.ingest.sentry.io/6118691",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0,
    release: 'v' + version,
    autoSessionTracking: false,
    debug: false
});

ReactDOM.render(
    <Fragment>
        <Provider store={store}>
            <BrowserRouter>
                <MainProvider>
                    <Routes/>
                </MainProvider>
            </BrowserRouter>
        </Provider>
    </Fragment>,
    document.getElementById('root')
);

serviceWorkerRegistration.register();

