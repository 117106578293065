import React from "react";
import {Modal} from "semantic-ui-react";
import {useSelector} from "react-redux";
import SpotsForm from "./SpotsForm";

const SpotsModal = ({user_id, playlist_id}) => {

    const crud = useSelector(({Spots}) => Spots.crud);
    const entity = useSelector(({Spots}) => Spots.entity);

    return (
        <Modal
            size={"mini"}
            open={crud.showModal && crud.action !== "delete" ? true : false}
        >
            <Modal.Header
                content={entity.id ? "Editar Anúncio" : "Criar Anúncio"}
            />
            <Modal.Content>
                <SpotsForm
                    entity={entity}
                    user_id={user_id}
                />
            </Modal.Content>
        </Modal>
    );
}

export default SpotsModal;