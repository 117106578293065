import {DateTime} from "luxon";

export const getRandomInt = (min, max) => {
    // format
    min = Math.ceil(min);
    max = Math.floor(max);

    // return number
    return Math.floor(Math.random() * (max - min)) + min;
}

export async function getCacheKeys() {
    return await window.caches.keys();
}

export async function clearCache() {
    const keys = await getCacheKeys();
    for(const key of keys){
        await window.caches.delete(key);
    }

    window.location.reload();
}

export async function getCache(cache) {
    try{
        return await window.caches.open(cache);
    }catch (e) {
        return false;
    }
}

export async function addUrlToCache(cache, url) {
    return await window.caches.open(cache).then(async (cache) => {
        await cache.match(url).then(async (res) => {
            console.log("in cache");
            if(!res){
                console.log("need cache");
                await cache.add(url).then((res) => {
                    console.log("adicionado com sucesso");
                }, (e) => {
                    console.log("erro ao adicionar");
                })
            }
        })
    }, e => console.log("erro ao abrir cache"));
}

export async function addToCache(cache, url) {
    return await window.caches.open(cache).then(res => res.add(url), e => console.log(e));
}

export function hasExpired(dt){

    const now = DateTime.now();
    const expires = DateTime.fromISO(dt).endOf('day');

    return expires.ts < now.ts;
}