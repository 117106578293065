import React from "react";
import {Modal} from "semantic-ui-react";
import UsersForm from "./UsersForm";
import {useSelector} from "react-redux";

const UsersModal = () => {
    const entity = useSelector(({Users}) => Users.entity);
    const crud = useSelector(({Users}) => Users.crud);

    return (
        <Modal
            size={"tiny"}
            open={crud.showModal && crud.action !== "delete" ? true : false}
        >
            <Modal.Header>
                {entity.id ? "Editar" : "Adicionar"} Cliente
                {
                    entity.id && <small style={{float: "right"}}>c{entity.id}</small>
                }
            </Modal.Header>
            <Modal.Content>
                <UsersForm/>
            </Modal.Content>
        </Modal>
    );
}

export default UsersModal;