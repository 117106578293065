import {combineReducers} from "redux";

import Musics from './Musics';
import Playlists from './Playlists';
import Spots from './Spots';
import Users from './Users';

const AppReducer = combineReducers({
    Musics,
    Playlists,
    Spots,
    Users,
});

export default AppReducer;
