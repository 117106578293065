import React, {Fragment, useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {spotsActions} from "../../redux/actions";
import {MainContainer, MusicContainer, MusicsContainer} from "./style";
import {Button, Icon} from "semantic-ui-react";
import {Title} from "./components/Title";

import Empty from "../Empty/Empty";
import Swal from "sweetalert2";
import SpotsModal from "./SpotsModal";
import {useHistory} from "react-router-dom";

const Spot = ({spot}) => {
    const dispatch = useDispatch();

    return (
        <MusicContainer>
            <div>
                <Icon
                    name={"user"}
                />
            </div>
            <div>
                <div>{spot.name}</div>
                <div>
                    <span style={{
                        width: "72px",
                        display: "inline-flex"
                    }}>
                        <Icon
                            name={"clock outline"}
                        />{spot.start_at.substr(11, 5)}
                    </span>
                    <span style={{
                        width: "72px",
                        display: "inline-flex"
                    }}>
                        <Icon
                            name={"clock outline"}
                        />{spot.end_in.substr(11, 5)}
                    </span>
                    <span style={{
                        width: "72px",
                        display: "inline-flex"
                    }}>
                        <Icon
                            name={"refresh"}
                        />{spot.interval}
                    </span>
                </div>
            </div>
            <div>
                {spot.active ? 'Ativo' : 'Inativo'}
            </div>
            <div>
                <Button.Group
                    icon
                    vertical
                    inverted
                    basic
                    size={"tiny"}
                >
                    <Button
                        icon={"pencil"}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            dispatch(spotsActions.toggle(true, "edit", spot));
                        }}
                    />
                    <Button
                        icon={"trash"}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            dispatch(spotsActions.toggle(true, "delete", spot));
                        }}
                    />
                </Button.Group>
            </div>
        </MusicContainer>
    );
}

const Main = ({spots, user_id}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const createSpot = () => {
        dispatch(spotsActions.toggle(true, "create"));
    }

    const goClients = () => {
        history.push('/client/home');
    }

    return (
        <Fragment>
            <div id={"playlistScroll"}>
                {
                    spots.data.length
                        ? <MusicsContainer>
                            {
                                spots.data.map((spot) => {
                                    return (
                                        <Spot
                                            key={`user-${spot.id}`}
                                            spot={spot}
                                        />
                                    )
                                })
                            }
                        </MusicsContainer>
                        : <Empty
                            icon={"announcement"}
                            title={"Nenhuma anúncio encontrado"}
                            message={"Começe agora mesmo adicionando um novo Anúncio"}
                        />
                }
            </div>
            <div id={"playlistActions"}>
                <SpotsModal user_id={user_id}/>
                <Button.Group widths={2}>
                    <Button
                        basic
                        inverted
                        content={"VER CLIENTES"}
                        onClick={goClients}
                    />
                    <Button
                        fluid
                        basic
                        inverted
                        content={"ADICIONAR ANÚNCIO"}
                        onClick={() => createSpot()}
                    />
                </Button.Group>
            </div>
        </Fragment>
    );
}

const Spots = ({user_id}) => {
    const dispatch = useDispatch();

    const spots = useSelector(({Spots}) => Spots.results);
    const save = useSelector(({Spots}) => Spots.save);
    const crud = useSelector(({Spots}) => Spots.crud);
    const entity = useSelector(({Spots}) => Spots.entity);

    const getUsers = useCallback(() => {
        dispatch(spotsActions._get({user_id}));
    }, [dispatch, user_id]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    useEffect(() => {
        if (save.success) {
            getUsers();
        }
    }, [save, getUsers]);

    useEffect(() => {
        if (crud.showModal && crud.action === "delete") {
            Swal.fire({
                title: 'Atenção!',
                text: 'Deseja realmente excluir este anúncio?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Sim, quero excluir!',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(spotsActions._delete(entity.id));
                } else {
                    dispatch(spotsActions.toggle(false));
                }
            });
        }
    }, [dispatch, entity, crud]);

    return (
        <MainContainer>
            <Title
                loading={spots.loading}
                counter={spots.data.length}
                duration={0}
                title={"Anúncios"}
            />
            <Main
                spots={spots}
                user_id={user_id}
            />
        </MainContainer>
    );
}

export default Spots;