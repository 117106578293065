import React, {useEffect} from "react";
import {Button, Form, Input} from "semantic-ui-react";
import {useForm} from "react-hook-form";
import {musicsActions, spotsActions, usersActions} from "../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";
import {APP} from "../../constants"

const SpotsForm = ({user_id, playlist_id, entity}) => {
    const dispatch = useDispatch();
    const save = useSelector(({Spots}) => Spots.save);

    const {register, unregister, handleSubmit, setValue, formState, errors, trigger, reset, watch} = useForm({
        mode: 'onChange',
        defaultValues: {
            id: entity.id || "",
            name: entity.name || "",
            type: entity.interval || 'P',
            active: entity.active || '1'
        }
    });

    const closeModal = async () => {
        await dispatch(spotsActions.toggle(false));
    }

    useEffect(() => {
        if (save.success && !save.loading) {
            async function call() {
                await reset();
                await closeModal();
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Anúncio ${entity.id ? 'editado' : 'adicionado'} com sucesso!`,
                    showConfirmButton: false,
                    timer: 2000,
                    toast: true,
                    backdrop: false
                });
            }

            call();
        }
    }, [save]);

    useEffect(() => {
        register('name', {required: true, minLength: 3});
        register('file', {required: true});
        register('start_at', {required: true});
        register('end_in', {required: true});
        register('interval', {required: true});
        register('type', {required: true});
        register('active', {required: true});
    }, [register]);

    useEffect(() => {
        if (!entity.id) {
            return;
        }

        unregister('file');

        setValue('id', entity.id);
        setValue('name', entity.name);
        setValue('active', entity.active ? '1' : '0');

        setValue("start_at", entity.start_at.substr(11, 5));
        setValue("end_in", entity.end_in.substr(11, 5));
        setValue("interval", entity.interval.slice(0, -1));
        setValue("type", entity.interval.slice(-1));

        trigger();

    }, [entity]);

    const onSubmit = async (data) => {

        if (data.id) {

            data["interval"] = data["interval"]+data["type"];

            dispatch(spotsActions._put(data));
        } else {


            let fd = await new FormData(document.getElementById("SpotsForm"));

            const signature = await usersActions.getSignature(user_id);
            if (signature.success) {
                const params = signature.data.params;
                const upload = await spotsActions.uploadMusic(params, fd);

                if(upload.id){
                    await fd.delete('file');
                    await fd.set('user_id', user_id);
                    await fd.set('interval', data.interval + data.type);
                    await fd.set('active', data.active);
                    await fd.set('file_unique', upload.id);
                    await fd.set('file_url', upload.url_short);
                    await fd.set('file_dir', 'spots');
                    await fd.set('file_driver', signature.data.driver);
                    await fd.set('duration', upload.duration);
                    await fd.set('file_size', upload.size);
                    await fd.set('file_type', upload.type + '/' + upload.extension);

                    dispatch(spotsActions._post(fd));
                }
            }
        }
    };

    const {isValid} = formState;
    const data = watch();

    return (
        <Form
            id={"SpotsForm"}
            onSubmit={handleSubmit(onSubmit)}
            loading={save.loading}
        >
            <input type={'hidden'} name={'id'} defaultValue={entity.id || ""} ref={register()}/>

            <Form.Field
                name={"name"}
                label={"Nome do Anúncio"}
                onChange={async (e, {name, value}) => {
                    await setValue(name, value);
                    await trigger(name);
                }}
                value={data.name}
                control={Input}
                autoFocus
            />

            {
                !entity.id && <Form.Field
                    name={"file"}
                    label={"Arquivo"}
                    type={"file"}
                    onChange={async (e, {name, value}) => {
                        await setValue(name, value);
                        await trigger(name);
                    }}
                    control={Input}
                />
            }

            <Form.Group widths={2}>
                <Form.Field
                    name={"start_at"}
                    label={"Início"}
                    type={"time"}
                    onChange={async (e, {name, value}) => {
                        await setValue(name, value);
                        await trigger(name);
                    }}
                    value={data.start_at}
                    control={Input}
                />
                <Form.Field
                    name={"end_in"}
                    label={"Término"}
                    type={"time"}
                    onChange={async (e, {name, value}) => {
                        await setValue(name, value);
                        await trigger(name);
                    }}
                    value={data.end_in}
                    control={Input}
                />
            </Form.Group>
            <Form.Group widths={2}>
                {/*<Form.Field*/}
                {/*    name={"interval"}*/}
                {/*    label={"Intervalo"}*/}
                {/*    type={"number"}*/}
                {/*    onChange={async (e, {name, value}) => {*/}
                {/*        await setValue(name, value);*/}
                {/*        await trigger(name);*/}
                {/*    }}*/}
                {/*    value={data.interval}*/}
                {/*    control={Input}*/}
                {/*/>*/}
                <Form.Select
                    fluid
                    name={'interval'}
                    label={'Intervalo'}
                    options={APP.options.interval}
                    value={data.interval}
                    onChange={async (e, {name, value}) => {
                        await setValue(name, value);
                        await trigger(name);
                    }}
                />
                <Form.Select
                    fluid
                    name={'type'}
                    label={'Tipo'}
                    options={APP.options.type}
                    value={data.type}
                    onChange={async (e, {name, value}) => {
                        await setValue(name, value);
                        await trigger(name);
                    }}
                />
            </Form.Group>
            <Form.Field>
                <Form.Select
                    fluid
                    name={'active'}
                    label={'Ativo'}
                    options={APP.options.sn}
                    value={entity.id ? entity.active ? '1' : '0' : '1'}
                    onChange={async (e, {name, value}) => {
                        await setValue(name, value);
                        await trigger(name);
                    }}
                />
            </Form.Field>
            <div
                style={{textAlign: "right"}}
            >
                <Button
                    basic
                    type={"button"}
                    onClick={() => {
                        reset();
                        closeModal();
                    }}
                >
                    Fechar
                </Button>
                <Button
                    primary
                    type={"submit"}
                    disabled={!isValid}
                >
                    Salvar
                </Button>
            </div>
        </Form>
    );
}

export default SpotsForm;