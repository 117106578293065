import {APP, PLAYLISTS} from "../../constants";

const start = APP.defaultState;

export default function reducer(state = start, action) {

    const results = state.results;

    switch (action.type) {
        case PLAYLISTS.ENTITY:
            return {...state, entity: {...start.entity, ...action.payload}};

        case PLAYLISTS.LIST:
            return {...state, results: {...state.results, loading: true}};

        case PLAYLISTS.LIST_SUCCESS:
            return {...state, results: {...start.results, ...action.payload}, save: start.save, crud: start.crud};

        case PLAYLISTS.LIST_FAILURE:
            return {...state, results: {...results, loading: false, error: action.payload}};

        case PLAYLISTS.CREATE:
        case PLAYLISTS.UPDATE:
        case PLAYLISTS.DELETE:
            return {...state, save: {...start.save, loading: true}};

        case PLAYLISTS.CREATE_SUCCESS:
        case PLAYLISTS.UPDATE_SUCCESS:
        case PLAYLISTS.DELETE_SUCCESS:
            return {...state, save: {...start.save, ...action.payload}};

        case PLAYLISTS.CREATE_FAILURE:
        case PLAYLISTS.UPDATE_FAILURE:
        case PLAYLISTS.DELETE_FAILURE:
            return {...state, save: {...start.save, error: action.payload}};

        default:
            return state;
    }
}
