import React, {useCallback, useContext, useEffect, useState} from "react";
import {Grid, Icon, Progress} from "semantic-ui-react";
import {useHistory, useParams} from "react-router-dom";
import {useSearchParam} from "react-use";

import Player from "../../../components/Player/Player";
import Playlist from "../../../components/Playlist/Playlist";

import MainContext from "../../../contexts/MainContext";

import {StyledMessage, WrappedGrid} from './style';
import {usersActions} from "../../../redux/actions";

const Home = () => {
    const history = useHistory();
    const type = useSearchParam("type");
    const params = useParams();
    const user_id = params.user_id || 0;

    const [status, setStatus] = useState({
        "limit": 0,
        "used": 0,
        "percent": 0,
        "success": false,
        "warning": false,
        "error": false,
    });

    const selectUser = useCallback((id) => {
        history.push(`/client/home/${id}/playlists`);
    }, [history]);

    const {signed, logout} = useContext(MainContext);
    const role = React.useMemo(() => signed.role, [signed]);

    useEffect(() => {
        if (!user_id && signed.role === "user") {
            selectUser(signed.id);
        }
    }, [selectUser, user_id, signed]);

    useEffect(() => {
        async function run() {
            try {
                const res = await usersActions.getStatus();
                if (res.success) {
                    const {disk} = res.data;
                    setStatus(disk);
                }
            } catch (e) {
                console.log(e);
            }
        }

        run().then();
    }, [params.type]);

    return (
        <WrappedGrid>
            <Grid.Row>
                <Grid.Column only='computer'>
                    <StyledMessage color={"black"}>
                        <Icon name='info circle'/> Seja bem vindo!
                        <span
                            style={{
                                float: "right",
                                cursor: "pointer",
                            }}
                            onClick={logout}
                        >Encerrar acesso</span>
                    </StyledMessage>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "calc(100% - 47px)"
                    }}>
                        <img
                            src={"/images/logo-inverted.svg"}
                            style={{
                                maxWidth: 300,
                                maxHeight: "65%",
                                margin: "0 auto"
                            }}
                            alt={"Radio Inteligente"}
                        />
                    </div>

                </Grid.Column>
                <Grid.Column id={"playlistContainer"} color={"black"}>
                    {
                        role === "admin" && <Progress
                            inverted
                            progress
                            percent={status.percent}
                            success={status.success}
                            warning={status.warning}
                            error={status.error}
                        >
                            Utilizando {status.used}GB de {status.limit}GB disponíveis
                        </Progress>
                    }
                    <StyledMessage color={"black"} style={{display: "none"}}>
                        <Icon name='info circle'/> Seja bem vindo!
                        <span
                            style={{
                                float: "right",
                                cursor: "pointer",
                            }}
                            onClick={logout}
                        >Encerrar acesso</span>
                    </StyledMessage>
                    <Playlist role={role} type={type}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{height: "fit-content"}}>
                <Grid.Column color={"black"}>
                    <Player/>
                </Grid.Column>
            </Grid.Row>
        </WrappedGrid>
    );
}

export default Home;