import {APP, MUSICS} from "../../constants";

const start = {
    ...APP.defaultState,
    played: [],
};

export default function reducer(state = start, action) {

    const results = state.results;

    switch (action.type) {
        case MUSICS.ENTITY:
            return {...state, entity: {...start.entity, ...action.payload}};

        case MUSICS.PLAY:
            return {...state, played: action.payload.index === -1 ? [] : [...state.played, action.payload.index]};

        case MUSICS.CRUD:
            return {
                ...state,
                crud: {...start.crud, ...action.payload},
                entity: action.payload.entity,
                save: start.save,
            };

        case MUSICS.LIST:
            return {...state, results: {...results, loading: true}};

        case MUSICS.LIST_SUCCESS:
            return {...state, results: {...start.results, ...action.payload}};

        case MUSICS.LIST_FAILURE:
            return {...state, results: {...results, loading: false, error: action.payload}};

        case MUSICS.CREATE:
        case MUSICS.UPDATE:
        case MUSICS.DELETE:
            return {...state, save: {...start.save, loading: true}};

        case MUSICS.CREATE_SUCCESS:
        case MUSICS.UPDATE_SUCCESS:
        case MUSICS.DELETE_SUCCESS:
            return {...state, save: {...start.save, ...action.payload}};

        case MUSICS.CREATE_FAILURE:
        case MUSICS.UPDATE_FAILURE:
        case MUSICS.DELETE_FAILURE:
            return {...state, save: {...start.save, error: action.payload}};

        default:
            return state;
    }
}
