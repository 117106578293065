import React, {useCallback, useContext, useEffect, useState} from "react";
import {Button, Icon} from "semantic-ui-react";

import MainContext from "../../contexts/MainContext";

import {MainContainer, MusicContainer, MusicsContainer} from "./style";
import {useDispatch, useSelector} from "react-redux";
import {musicsActions, spotsActions} from "../../redux/actions";

import Swal from "sweetalert2";
import MusicsModal from "./MusicsModal";
import {Title} from "./components/Title";
import Empty from "../Empty/Empty";
import {useHistory, useParams} from "react-router-dom";
import {useLocalStorage, useNetwork} from "react-use";
import {addUrlToCache, getRandomInt, hasExpired} from "../../utils";

const Music = ({role, music, editMusic, deleteMusic, expires_in, goPlaylists}) => {

    const {music: active, setMusic} = useContext(MainContext);

    const duration = new Date(music.duration * 1000).toISOString().substr(14, 5);
    const expired = hasExpired(expires_in);

    if (role === "user" && expired) {
        goPlaylists();
    }

    return (
        <MusicContainer
            id={`music-${music.id}`}
            className={music.id === active.id ? 'active' : ''}
            onClick={() => setMusic({...music, direct: true})}
        >
            <div>
                <Icon
                    name={"music"}
                    // style={{
                    //     color: (music.normalized ? "#FFF" : "rgba(255, 255, 255, .5)")
                    // }}
                    className={music.normalized ? "normalized" : ""}
                />
            </div>
            <div>
                <div title={music.name}>{music.name}</div>
                <div>{music.artist || '-'}</div>
            </div>
            <div>
                {duration}
            </div>
            {
                role === "admin" && <div>
                    <Button.Group
                        icon
                        vertical
                        inverted
                        basic
                        size={"tiny"}
                    >
                        <Button
                            icon={"pencil"}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                editMusic(music);
                            }}
                        />
                        <Button
                            icon={"trash"}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                deleteMusic(music.id);
                            }}
                        />
                    </Button.Group>
                </div>
            }
        </MusicContainer>
    );
}

const Musics = ({role}) => {
    const network = useNetwork();
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    // const playlists = useSelector(({Playlists}) => Playlists.results);
    const spots = useSelector(({Spots}) => Spots.results);
    const musics = useSelector(({Musics}) => Musics.results);
    const save = useSelector(({Musics}) => Musics.save);
    const [duration, setDuration] = useState("00:00:00");
    const [downloadStarted, setDownloadStarted] = useState(false);

    const {music, setMusic} = useContext(MainContext);

    const user_id = params.user_id || 0;
    const playlist_id = params.playlist_id || 0;
    // const playlist = playlists.data.filter((item) => item.id === parseInt(playlist_id));
    const CACHE = `MUSICS_${playlist_id}_DOWNLOAD`;
    const [cache, setCache] = useLocalStorage(CACHE, 0);

    let expires_in = "3099-12-31";
    // if(playlist[0].expires_in){
    //     expires_in = playlist[0].expires_in;
    // }

    const goPlaylists = () => {
        history.push(`/client/home/${user_id}/playlists`)
    }

    const getMusics = useCallback(async (playlist_id) => {
        dispatch(spotsActions._get({active: 1, user_id, online: network.online}));
        dispatch(musicsActions._get({playlist_id, online: network.online}));
    }, [dispatch, network, user_id]);

    const createMusic = () => {
        dispatch(musicsActions.toggle(true, "create"));
    }

    const editMusic = (music) => {
        dispatch(musicsActions.toggle(true, "edit", music));
    }

    const deleteMusic = (music_id) => {
        Swal.fire({
            title: 'Atenção!',
            text: 'Deseja realmente excluir esta música?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Sim, quero excluir!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(musicsActions._delete(music_id));
            }
        });
    }

    useEffect(() => {
        async function calcDuration() {
            // get total time
            const totalTime = await musics.data.reduce((total, value) => {
                return total + value.duration;
            }, 0);

            // get time string
            const duration = new Date(totalTime * 1000).toISOString().substr(11, 8);

            // set new value
            setDuration(duration);
        }

        // noinspection JSIgnoredPromiseFromCall
        calcDuration();

        // select first
        if (!music.id && musics.data.length > 0) {
            let start = getRandomInt(0, musics.data.length);
            let first = {...musics.data[start], autoPlay: false};

            window.location.hash = `#music-${first.id}`;

            setMusic(first);
        }

    }, [setMusic, musics, music]);

    useEffect(() => {
        async function call() {
            await getMusics(playlist_id);
        }

        call();

    }, [getMusics, playlist_id]);

    useEffect(() => {
        if (save.success) {
            getMusics(playlist_id);
        }
    }, [getMusics, save, playlist_id])

    useEffect(() => {
        let isCancelled = false;

        if (!network.online || !musics.data || isCancelled || downloadStarted || role !== 'user') {
            return;
        }

        const fetchData = async () => {
            const max = musics.data.length;

            for (let i = 0; i < max; i++) {

                setDownloadStarted(true);

                if (isCancelled) {
                    break;
                }

                if (spots.data[i]) {
                    try {
                        await addUrlToCache('musics', spots.data[i]['file_url']);
                    } catch (e) {
                        console.log(e);
                    }
                }

                try {
                    await addUrlToCache('musics', musics.data[i]['file_url']);
                    setCache((i + 1) * 100 / max);
                } catch (e) {
                    console.log(e);
                }
            }

            localStorage.setItem(CACHE, '100');
        }

        fetchData();

        // return () => {
        //     isCancelled = true;
        // };

    }, [musics]);

    return (
        <MainContainer>
            <Title
                loading={musics.loading}
                counter={musics.data.length}
                duration={duration}
                title={"Lista de Músicas"}
                cache={cache}
            />
            <div id={"playlistScroll"}>
                {
                    musics.data.length
                        ? <MusicsContainer>
                            {
                                musics.data.map((music, i) => {
                                    return (
                                        <Music
                                            key={`music-${i}`}
                                            role={role}
                                            music={music}
                                            editMusic={editMusic}
                                            deleteMusic={deleteMusic}
                                            expires_in={expires_in}
                                            goPlaylists={goPlaylists}
                                        />
                                    )
                                })
                            }
                        </MusicsContainer>
                        : <Empty
                            icon={"music"}
                            title={"Nenhuma música encontrada"}
                            message={"Começe agora mesmo enviando a sua primeira música"}
                        />
                }

            </div>
            <div id={"playlistActions"}>
                {
                    role === "admin"
                        ? <>
                            <MusicsModal
                                user_id={user_id}
                                playlist_id={playlist_id}
                            />
                            <Button.Group widths={"two"}>
                                <Button
                                    fluid
                                    basic
                                    inverted
                                    content={"VER PLAYLISTS"}
                                    onClick={goPlaylists}
                                />
                                <Button
                                    fluid
                                    basic
                                    inverted
                                    content={"ENVIAR MÚSICAS"}
                                    onClick={() => createMusic()}
                                />
                                {/*<Button*/}
                                {/*    fluid*/}
                                {/*    basic*/}
                                {/*    inverted*/}
                                {/*    content={"RECARREGAR LISTA"}*/}
                                {/*    onClick={() => getMusics(playlist_id)}*/}
                                {/*/>*/}
                            </Button.Group>
                        </>
                        : <Button.Group widths={"two"}><Button
                            fluid
                            basic
                            inverted
                            content={"VER PLAYLISTS"}
                            onClick={goPlaylists}
                        />
                            <Button
                                fluid
                                basic
                                inverted
                                content={"ATUALIZAR MÚSICAS"}
                                onClick={() => getMusics(playlist_id)}
                            />
                        </Button.Group>
                }
            </div>
        </MainContainer>
    );
}

export default Musics;