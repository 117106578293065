import styled from "styled-components";
import {Grid, Message} from "semantic-ui-react";

const playlistWidth = 420;

export const WrappedGrid = styled(Grid)`
    height: 100%;
    margin: 0 !important;
    flex-flow: column nowrap !important;
    background: url("/images/home/abstract-bg.jpg");
    background-size: cover;
    background-position: center center;
    
    > div:nth-child(1) {
        height: calc(100% - 136px);
        
        > div {
            height: -webkit-fill-available;
        }
    
        > div:nth-child(1) {
            flex-grow: 1;
        }
        
        > div#playlistContainer {
            width: ${playlistWidth}px;
            background-color: rgba(0, 0, 0, .5) !important;
            overflow: hidden;
            display: flex;
            flex-direction: column;
        }
    }
    @media only screen and (max-width: 1023px) {
        div#playlistContainer {
            width: 100% !important;
        }
        .ui.black.message{
            display: block !important;
        }
    }
`;

export const StyledMessage = styled(Message)`
    background-color: rgba(0, 0, 0, .5) !important;
`;