import React from "react";
import {Icon, Loader} from "semantic-ui-react";
import {StyledHeader, TitleContainer} from "../style";

const Cache = ({cache}) => {
    if(!cache){
        return <React.Fragment/>
    }

    return (
        <div style={{
            float: "right",
            fontSize: 12,
            fontWeight: "normal",
        }}>
            {cache === 100 ? <Icon name={"checkmark"}/> : `${cache.toFixed(2)}%`}
        </div>
    );
}

export const Title = ({title, loading, counter, duration, cache}) => {
    return (
        <TitleContainer>
            {/*<Dimmer*/}
            {/*    active={loading}*/}
            {/*>*/}
            {/*    <Loader/>*/}
            {/*</Dimmer>*/}
            <div id={"mainCounter"}>
                <div>
                    {
                        !loading ? `${counter} registro(s)` : <Loader inline active={true} size={"tiny"} inverted/>
                    }
                </div>
                <div>
                    {
                        duration
                            ? <>
                                <Icon
                                    name={"clock outline"}
                                />{' '}{duration}
                            </>
                            : ''
                    }
                </div>
            </div>
            <div>
                <StyledHeader
                    inverted
                    size={"huge"}
                    // onClick={() => alert('v3')}
                >
                    {title}
                    <Cache cache={cache}/>
                </StyledHeader>
            </div>
        </TitleContainer>
    );
}