import React, {useEffect} from "react";
import {Modal} from "semantic-ui-react";
import MusicsUpload from "./MusicsUpload";
import {useSelector} from "react-redux";
import MusicsEdit from "./MusicsEdit";

const MusicsModal = ({user_id, playlist_id}) => {

    const crud = useSelector(({Musics}) => Musics.crud);
    const entity = useSelector(({Musics}) => Musics.entity);

    return (
        <Modal
            size={entity.id ? "mini" : "small"}
            open={crud.showModal && crud.action !== "delete" ? true : false}
        >
            <Modal.Header
                content={entity.id ? "Editar Música" : "Enviar Músicas"}
            />
            <Modal.Content>
                {
                    entity.id
                        ? <MusicsEdit
                            entity={entity}
                        />
                        : <MusicsUpload
                            user_id={user_id}
                            playlist_id={playlist_id}
                        />
                }
            </Modal.Content>
        </Modal>
    );
}

export default MusicsModal;