import React from "react";
import {useParams} from "react-router-dom";

import Musics from "./Musics";
import Playlists from "./Playlists";
import Spots from "./Spots";
import Users from "./Users";

const Playlist = ({role, type}) => {

    const params = useParams();

    if (role === 'admin' && !params.user_id) {
        return <Users/>
    }

    if(params.type === "spots"){
        return <Spots user_id={params.user_id}/>
    }

    if (params.playlist_id) {
        return <Musics
            role={role}
        />
    } else {
        return <Playlists
            role={role}
        />
    }
}

export default Playlist;