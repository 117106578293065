import React, {useState} from "react";
import {Button, Form, Header, Icon, Progress, Segment, Table} from "semantic-ui-react";
import {MusicsUploadContainer} from "./style";
import HeaderSubHeader from "semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader";
import {useFieldArray, useForm} from "react-hook-form";
import {musicsActions} from "../../redux/actions";
import {useDispatch} from "react-redux";
import getBlobDuration from "get-blob-duration";
import Swal from "sweetalert2";
import {MUSICS} from "../../constants";

const EditFiles = ({handleSubmit, onSubmit, fields, progress}) => {
    return (
        <div style={{overflow: "hidden"}}>
            <Header>
                {fields.length} Arquivo(s) selecionado(s)
            </Header>
            <Form
                id={"upload"}
                onSubmit={handleSubmit(onSubmit)}
            >
                <Table className={"scroll"} size={"small"}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                width={6}
                                content={"Nome"}
                            />
                            <Table.HeaderCell
                                width={6}
                                content={"Artista"}
                            />
                            <Table.HeaderCell
                                width={4}
                                content={""}
                            />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            fields.map((item, i) => {
                                return (
                                    <Table.Row
                                        id={`music-upload-${i}`}
                                        key={item.id}
                                    >
                                        <Table.Cell
                                            width={6}
                                        >
                                            {/*<Controller*/}
                                            {/*    as={<input/>}*/}
                                            {/*    name={`file[${i}].name`}*/}
                                            {/*    control={control}*/}
                                            {/*    defaultValue={item.name}*/}
                                            {/*    maxLength={60}*/}
                                            {/*    disabled={true}*/}
                                            {/*/>*/}
                                            {item.name}
                                        </Table.Cell>
                                        <Table.Cell
                                            width={6}
                                        >
                                            {/*<Controller*/}
                                            {/*    as={<input/>}*/}
                                            {/*    name={`file[${i}].artist`}*/}
                                            {/*    control={control}*/}
                                            {/*    defaultValue={item.artist}*/}
                                            {/*    maxLength={60}*/}
                                            {/*    disabled={true}*/}
                                            {/*/>*/}
                                            {item.artist}
                                        </Table.Cell>
                                        <Table.Cell
                                            width={4}
                                        >
                                            {/*<Controller*/}
                                            {/*    as={<input/>}*/}
                                            {/*    type={'hidden'}*/}
                                            {/*    name={`file[${i}].id`}*/}
                                            {/*    control={control}*/}
                                            {/*    defaultValue={item.id}*/}
                                            {/*/>*/}
                                            {/*<Controller*/}
                                            {/*    as={<input/>}*/}
                                            {/*    type={'hidden'}*/}
                                            {/*    name={`file[${i}].duration`}*/}
                                            {/*    control={control}*/}
                                            {/*    defaultValue={item.duration}*/}
                                            {/*/>*/}
                                            <Progress
                                                indicating
                                                color={"green"}
                                                size={"tiny"}
                                                percent={progress[i] || 0}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })
                        }
                    </Table.Body>
                </Table>
            </Form>
        </div>
    );
}

const SelectFiles = ({append, setProgress, setStep}) => {
    return (
        <div>
            <label htmlFor={"inputMusicUpload"} style={{cursor: "pointer"}}>
                <Segment basic padded={"very"}>
                    <Header icon textAlign={"center"}>
                        <Icon
                            name={"cloud upload"}
                        />
                        Selecionar arquivos
                        <HeaderSubHeader
                            content={"Selecione as músicas que deseja enviar"}
                        />
                    </Header>
                </Segment>
            </label>
            <input
                multiple
                id={"inputMusicUpload"}
                type={"file"}
                name={"file"}
                style={{display: 'none'}}
                accept={'audio/*'}
                onChange={async (e) => {
                    let rows = e.target.files;
                    let artist = "";
                    for (const file of rows) {
                        const blob = URL.createObjectURL(file);
                        const duration = await getBlobDuration(blob);
                        const name = file.name.substring(0, file.name.lastIndexOf('.')).substring(0, 58);

                        await append({name, artist, duration, file});
                    }
                    await setProgress([]);
                    await setStep(2);
                }}
            />
        </div>
    );
}

const MusicsUpload = ({user_id, playlist_id}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [progress, setProgress] = useState([]);

    const {handleSubmit, reset, control} = useForm();

    const {fields, append, remove} = useFieldArray({
        control,
        name: "file"
    });

    const closeModal = async () => {
        await reset();
        dispatch(musicsActions.toggle(false));
    };

    const handleProgress = (percent, index) => {
        setProgress(prevState => {

            window.location.hash = `#music-upload-${index}`;

            let newState = [...prevState];
            newState[index] = percent;

            return newState;
        });
    }

    const sleep = async (time) => {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    const sendMusic = async (fd, index, attempts = 7) => {
        let attempt = 0;

        do {
            console.log("attempt: ", attempt);
            try {
                await dispatch(musicsActions._post(fd, (percent) => handleProgress(percent, index)));
                await sleep(1000);
                return true;
            } catch (e) {
                await handleProgress(7, index);
                await sleep(3000);
                ++attempt;
            }
        } while (attempt < attempts);

        return false;
    }

    const onSubmit = async () => {

        setLoading(true);

        let success = true;
        let items = [];
        let index = 0;
        for (const field of fields) {
            const {name, artist, duration, file} = field;
            const fd = new FormData();
            fd.append("user_id", user_id);
            fd.append("playlist_id", playlist_id);
            fd.append("file", file);
            fd.append("name", name);
            fd.append("artist", artist);
            fd.append("duration", duration);
            fd.append("active", "1");
            fd.append("file_driver", "api");

            const send = await sendMusic(fd, index);
            if (!send) {
                items.push({name, artist, duration, file});
                success = false;
            }

            ++index;
        }

        let text = undefined;
        if (!success) {
            text = "As músicas que falharam permaneceram na lista para uma nova tentativa.";
        }

        await Swal.fire({
            allowOutsideClick: false,
            position: success ? "top-end" : "center",
            icon: success ? "success" : "warning",
            title: success ? "Músicas enviadas com sucesso!" : "Ocorreram falhas no envio!",
            text,
            showConfirmButton: !success,
            timer: success ? 2000 : undefined,
            backdrop: false,
            toast: success
        });

        if (success) {
            await closeModal();
        } else {
            await remove();
            for (const item of items) {
                await append(item);
            }
            await setLoading(false);
            await setProgress([]);
        }
    };

    React.useEffect(() => {
        return () => {
            dispatch({
                type: MUSICS.CREATE_SUCCESS,
                payload: {
                    data: {},
                    success: true,
                },
            });
        }
    }, [dispatch]);

    return (
        <MusicsUploadContainer>
            {
                step === 1
                    ? <SelectFiles
                        append={append}
                        setProgress={setProgress}
                        setStep={setStep}
                    />
                    : <EditFiles
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        fields={fields}
                        progress={progress}
                    />
            }
            <div>
                <Button
                    disabled={loading}
                    type={"button"}
                    content={"Fechar"}
                    onClick={closeModal}
                />
                <Button
                    primary
                    disabled={step === 1 || loading}
                    form={"upload"}
                    type={"submit"}
                    content={"Enviar"}
                    floated={"right"}
                />
                <Button
                    disabled={step === 1 || loading}
                    type={"button"}
                    content={"Voltar"}
                    onClick={async () => {
                        await setStep(1);
                        await reset();
                    }}
                    floated={"right"}
                />
            </div>
        </MusicsUploadContainer>
    );
}

export default MusicsUpload;