import React from "react";
import {Button, Header, Icon, Segment} from "semantic-ui-react";

const Empty = ({icon, title, message}) => {
    return (
        <Segment
            basic
            style={{
                width: "100%",
                textAlign: "center"
            }}
        >
            <Header icon inverted>
                <Icon
                    name={icon}
                    style={{
                        fontSize: '3rem',
                        paddingBottom: '1rem',
                    }}
                />
                {title}
                <Header.Subheader>
                    {message}
                </Header.Subheader>
                <br/><br/>
                {/*<Button*/}
                {/*    basic*/}
                {/*    inverted*/}
                {/*    content={"Atualizar"}*/}
                {/*    onClick={async () => {*/}
                {/*        await setLoading(true);*/}
                {/*        await getPlaylist();*/}
                {/*        await setLoading(false);*/}
                {/*    }}*/}
                {/*/>*/}
            </Header>
        </Segment>
    );
}

export default Empty;