import React, {useContext, useEffect, useState} from "react";
import {Button, Progress} from "semantic-ui-react";
import {useAudio} from "react-use";
import {version} from '../../../package.json';

import MainContext from "../../contexts/MainContext";

import {ProgressContainer, Version, WrappedSegment} from "./style";
import {clearCache} from "../../utils";

let duration = '00:00';
let time = '00:00';
let percent = 0;

const path = "https://files.radiointeligente.com.br";

function fade(audio, controls){
    if(audio.volume > 0){
        audio.volume -= 0.1;
        setTimeout(fade, 2);
    }else{
        controls.pause();
    }
}

const Player = () => {

    const [fadeOut, setFadeOut] = useState(false)

    const {
        music, setPlayer,
        random, setRandom,
        repeat, setRepeat,
        prevMusic,
        nextMusic
    } = useContext(MainContext);

    const [goNext, setGoNext] = useState(false);
    const [audio, state, controls, ref] = useAudio({
        src: music.file_url ? music.file_url : undefined,
        autoPlay: false,
        preload: "auto",
    });

    // let fadeInterval = setInterval(function(){
    //
    //     console.log("fade interval");
    //
    //     if(state.volume <= 0){
    //         clearInterval(fadeInterval);
    //         return;
    //     }
    //     controls.volume( state.volume - 0.3);
    // }, 500);


    useEffect(() => {
        if (!state.duration) {
            return
        }

        if(state.paused){
            setFadeOut(false);
        }

        percent = (state.time * 100) / state.duration;
        duration = new Date(state.duration * 1000).toISOString().substr(14, 5);
        time = new Date(state.time * 1000).toISOString().substr(14, 5);

        if(state.time > state.duration - 4 ){
            setFadeOut(true);
        }

        if(!goNext && state.time === state.duration){
            setGoNext(true);
        }
    }, [state, goNext]);

    useEffect(() => {
        setPlayer(controls);
    }, []);

    useEffect(() => {
        if(goNext){
            nextMusic();
            setTimeout(() => setGoNext(false), 3000);
        }
    }, [goNext]);

    useEffect(() => {
        if(fadeOut){
            let timerID = setInterval(() => {
                if(ref.current.volume <=0){
                    setGoNext(true);
                    clearInterval(timerID);
                }else{
                    let volume = ref.current.volume - 0.033;

                    ref.current.volume = volume < 0 ? 0 : volume;
                }
            }, 100);

            return function cleanup() {
                clearInterval(timerID);
            };
        }
    }, [fadeOut]);

    useEffect(() => {
        if (!music.file_url || ( typeof music.autoPlay !== "undefined" && !music.autoPlay)) {
            return;
        }

        window.location.hash = `#music-${music.id}`;

        setFadeOut(false);

        controls.volume(1);
        controls.play();
    }, [music]);

    const disabled = music.file_dir === "spots" || (state.time > 0 && state.time < 3);

    return (
        <WrappedSegment
            inverted
            basic
            textAlign={"center"}
        >
            {audio}
            <Button
                inverted
                basic
                circular
                icon='random'
                // onClick={() => setRandom(!random)}
                active={random}
            />

            <Button
                inverted
                basic
                circular
                icon='step backward'
                onClick={prevMusic}
                disabled={disabled}
            />

            <Button
                inverted
                basic
                circular
                size='large'
                icon={state.paused ? "play" : "pause"}
                onClick={() => {
                    if (state.paused) {
                        controls.play();
                    } else {
                        controls.pause();
                    }
                }}
            />

            <Button
                inverted
                basic
                circular
                icon='step forward'
                onClick={nextMusic}
                disabled={disabled}
            />

            <Button
                inverted
                basic
                circular
                icon='sync'
                // onClick={() => setRepeat(!repeat)}
                active={repeat}
            />

            <ProgressContainer text>
                <div className='player-progress-time'>{time}</div>
                <div className='player-progress-bar'>
                    <Progress percent={percent} size='tiny' color='violet'/>
                </div>
                <div className='player-progress-time'>{duration}</div>
            </ProgressContainer>
            <Version onClick={clearCache}>v{version}</Version>
        </WrappedSegment>
    );
}

export default Player;