const save = {
    success: false,
    loading: false,
    error: false,
};

const results = {
    loading: false,
    success: false,
    error: false,
    data: [],
    pagination: {},
};

const defaultState = {
    entity: {},
    save,
    results,
    crud: {
        showModal: false,
        action: '',
    }
};

const apiUrl = process.env.NODE_ENV === "development"
    ? "https://api.radiointeligente.com.br"
    : "https://api.radiointeligente.com.br";

const sn = [
    {key: '1', text: 'Sim', value: '1'},
    {key: '0', text: 'Não', value: '0'},
];

const type = [
    {key: 'P', text: 'Músicas', value: 'P'},
    {key: 'M', text: 'Minutos', value: 'M'},
];

const interval = [
    {key: '5', text: '5', value: '5'},
    {key: '10', text: '10', value: '10'},
    {key: '15', text: '15', value: '15'},
    {key: '20', text: '20', value: '20'},
    {key: '30', text: '30', value: '30'},
];

export const APP = {
    apiUrl,
    defaultState,
    options: {
        sn,
        type,
        interval
    }
};
