import api from "../../services/api"
import {USERS} from "../../constants";

export const usersActions = {
    _get: getUsers,
    _post: postUsers,
    _put: putUsers,
    _delete: deleteUsers,
    getSignature,
    getStatus,
    entity,
    toggle
};

async function getSignature(id) {
    return new Promise(function (resolve, reject) {
        api
            .get('/users/get-signature/' + id)
            .then(({data}) => resolve(data))
            .catch(e => reject(e))
    });
}

async function getStatus() {
    return new Promise(function (resolve, reject) {
        api
            .get('/status')
            .then(({data}) => resolve(data))
            .catch(e => reject(e))
    });
}

function getUsers(params = {}) {
    return (dispatch) => {
        // loading
        dispatch(loading(USERS.LIST));

        // get data
        api
            .get('/users', {params})
            .then((res) => {
                dispatch({
                    type: USERS.LIST_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                console.log(e);
                dispatch({
                    type: USERS.LIST_FAILURE,
                    payload: e,
                })
            });
    }
}

function postUsers(data = {}) {
    return (dispatch) => {
        // loading
        dispatch(loading(USERS.CREATE));

        // get data
        api
            .post('/users', data)
            .then((res) => {
                dispatch({
                    type: USERS.CREATE_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                dispatch({
                    type: USERS.CREATE_FAILURE,
                    payload: e,
                })
            });
    }
}

function putUsers(data = {}) {
    return (dispatch) => {
        // loading
        dispatch(loading(USERS.UPDATE));

        // get data
        api
            .put(`/users/${data.id}`, data)
            .then((res) => {
                dispatch({
                    type: USERS.UPDATE_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                dispatch({
                    type: USERS.UPDATE_FAILURE,
                    payload: e,
                })
            });
    }
}

function deleteUsers(id) {
    return (dispatch) => {
        // loading
        dispatch(loading(USERS.DELETE));

        // get data
        api
            .delete(`/users/${id}`)
            .then((res) => {
                dispatch({
                    type: USERS.DELETE_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                dispatch({
                    type: USERS.DELETE_FAILURE,
                    payload: e,
                })
            });
    }
}

function loading(type, loading = true) {
    return {type, payload: loading};
}

function entity(payload) {
    return {type: USERS.ENTITY, payload};
}

function toggle(showModal, action = "", entity = {}) {
    return {type: USERS.CRUD, payload: {showModal, action, entity}};
}