import React, {useEffect} from "react";
import {Button, Form, Input, Modal} from "semantic-ui-react";
import {useForm} from "react-hook-form";
import {playlistsActions} from "../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";
import {APP} from "../../constants"

const PlaylistsModal = ({user_id, showModal, closeModal}) => {
    const dispatch = useDispatch();
    const save = useSelector(({Playlists}) => Playlists.save);
    const entity = useSelector(({Playlists}) => Playlists.entity);

    let expires = new Date();
    if (entity.expires_in) {
        expires = new Date(entity.expires_in);
    } else {
        expires.setDate(expires.getDate() + 30);
    }
    expires = expires.toISOString().split('T')[0];

    const {register, handleSubmit, setValue, formState, trigger, reset} = useForm({
        mode: 'onChange',
        defaultValues: {
            id: entity.id || "",
            user_id,
            name: entity.name || "",
            expires_in: expires,
            // description: entity.description || "",
            active: entity.active || "1",
        }
    });

    useEffect(() => {
        if (save.success && !save.loading) {
            async function call() {
                await reset();
                await closeModal();
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Playlist ${entity.id ? 'editada' : 'criada'} com sucesso!`,
                    showConfirmButton: false,
                    timer: 2000,
                    backdrop: false,
                    toast: true
                });
            }

            call();
        }
    }, [save]);

    useEffect(() => {
        register('name', {
            required: true,
            minLength: 3
        });
        register('expires_in', {
            required: true,
        });
        // register('description');
        register('active');
    }, [register])

    useEffect(() => {
        if (!entity.id) {
            return;
        }

        setValue('id', entity.id);
        setValue('name', entity.name);
        setValue('expires_in', expires);
        // setValue('description', entity.description);
        setValue('active', entity.active);

        trigger()

    }, [entity])

    const onSubmit = (data) => {
        if (data.id) {
            dispatch(playlistsActions._put(data));
        } else {
            dispatch(playlistsActions._post(data));
        }
    };

    const {isValid} = formState;

    return (
        <Modal
            size={"mini"}
            // dimmer={"inverted"}
            open={showModal}
        >
            <Modal.Header>{entity.id ? "Editar" : "Criar"} Playlist</Modal.Header>
            <Modal.Content>
                <Form
                    id={"PlaylistsModal"}
                    onSubmit={handleSubmit(onSubmit)}
                    loading={save.loading}
                >
                    <input type={'hidden'} name={'id'} defaultValue={entity.id || ""} ref={register()}/>
                    <input type={'hidden'} name={'user_id'} defaultValue={user_id} ref={register()}/>

                    <Form.Field
                        name={"name"}
                        label={"Nome"}
                        onChange={async (e, {name, value}) => {
                            await setValue(name, value);
                            await trigger(name);
                        }}
                        defaultValue={entity.name || ""}
                        control={Input}
                        autoFocus
                    />
                    <Form.Group widths={"equal"}>
                        <Form.Field
                            name={"expires_in"}
                            label={"Validade"}
                            onChange={async (e, {name, value}) => {
                                await setValue(name, value);
                                await trigger(name);
                            }}
                            defaultValue={expires}
                            control={Input}
                            type={"date"}
                        />
                        {/*<Form.Field*/}
                        {/*    name={"description"}*/}
                        {/*    label={"Descrição"}*/}
                        {/*    onChange={async (e, {name, value}) => {*/}
                        {/*        await setValue(name, value);*/}
                        {/*        await trigger(name);*/}
                        {/*    }}*/}
                        {/*    defaultValue={entity.description || ""}*/}
                        {/*    control={Input}*/}
                        {/*/>*/}
                        <Form.Field>
                            <Form.Select
                                fluid
                                name={'active'}
                                label={'Ativo'}
                                options={APP.options.sn}
                                defaultValue={entity.id ? (entity.active ? '1' : '0') : '1'}
                                onChange={async (e, {name, value}) => {
                                    await setValue('active', value);
                                    await trigger(name);
                                }}
                            />
                        </Form.Field>
                    </Form.Group>
                    <div
                        style={{textAlign: "right"}}
                    >
                        <Button
                            basic
                            type={"button"}
                            onClick={() => {
                                reset();
                                closeModal();
                            }}
                        >
                            Fechar
                        </Button>
                        <Button
                            primary
                            type={"submit"}
                            disabled={!isValid}
                        >
                            Salvar
                        </Button>
                    </div>
                </Form>
            </Modal.Content>
        </Modal>
    );
}

export default PlaylistsModal;