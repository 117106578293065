import React, {createContext, useEffect, useState} from 'react';
import {useBoolean, useInterval, useLocalStorage} from "react-use";
import {useHistory} from 'react-router-dom';
import * as Sentry from "@sentry/react";

import {getRandomInt} from '../utils/'
import {useDispatch, useSelector} from "react-redux";
import {musicsActions} from "../redux/actions";
import {DateTime} from "luxon";

const MainContext = createContext({});

Array.prototype.cycle = function (index, action, random = false) {

    const max = this.length;
    if(!max){
        return false;
    }

    let i = (action === "prev") ? index - 1 : index + 1;
    if(action === "next" && random){
        let finded = true
        while(finded){
            i = getRandomInt(0, max);
            if(i !== index){
                finded = false;
            }
        }
    }

    let row = this[i];

    if (typeof row === "undefined") {
        row = this[0];
    }

    return row;
};

let musicIndex = 0;

export const MainProvider = ({children}) => {
    // get dispatch
    const dispatch = useDispatch();

    const [signed, setSigned] = useLocalStorage('user', {});
    const [random, setRandom] = useLocalStorage('random', true);
    const [repeat, setRepeat] = useLocalStorage('repeat', true);
    const [playlist, setPlaylist] = useLocalStorage('playlist', {});

    const played = useSelector(({Musics}) => Musics.played);
    const musics = useSelector(({Musics}) => Musics.results.data);
    const spots = useSelector(({Spots}) => Spots.results.data);
    const [player, setPlayer] = useState({});
    const [playerStatus, setPlayerStatus] = useState('pause');
    const [music, setMusic] = useState({});
    const [musicCount, setMusicCount] = useState(1);
    // const [nextMusic, setNextMusic] = useState(false)

    const [spot, setSpot] = useState(false);
    const [delay] = React.useState(1000);
    const [isRunning] = useBoolean(true);

    const getNext = async (check = "P") => {
        // get now
        const now  = DateTime.now();

        // get spots
        const spot = await spots.filter(o => {
            const start_at = DateTime.fromISO(o.start_at).toMillis();
            const end_in = DateTime.fromISO(o.end_in).toMillis();

            return now.toMillis() >= start_at && now.toMillis() <= end_in;
        });

        // start next
        let next = false;

        // check spots
        if(spot.length > 0){
            const interval = parseInt(spot[0]['interval'].slice(0, -1));
            const type = spot[0]['interval'].slice(-1);

            if(check=== "P" && type === "P" && musicCount && (musicCount % interval) === 0){
                next = spot[0];
            }

            if(check=== "M" && type === "M" && now.minute % interval === 0){
                next = spot[0];
            }

            if(next && next.file_url === music.file_url){
                next = false;
            }
        }

        // return next
        return next;
    };

    useInterval(
        async () => {
            // have next spot?
            if(spot){
                return;
            }

            // get next
            let next = await getNext("M");

            // set spot
            setSpot(next);
        },
        isRunning ? delay : null
    );

    const history = useHistory();

    useEffect(() => {
        // check
        if (!musics.length || !music.id || music.file_dir === "spots") {
            return;
        }

        // set music index
        musicIndex = musics.findIndex((o) => o.id === music.id);

        // dispatch played
        dispatch(musicsActions.play(musicIndex));

    }, [dispatch, musics, music]);

    useEffect(() => {
        let user = null;
        if (signed.id) {
            user = {
                id: signed.id,
                name: signed.name,
                email: signed.email,
            };
        }
        Sentry.setUser(user);
    }, [signed]);

    const nextMusic = async () => {
        // last music
        if (!repeat && (musicIndex + 1) > (musics.length - 1)) {
            console.log("não passa");
            return;
        }

        // start next
        let next = spot ? spot : await getNext("P");

        if(!next){
            // get next music
            next = musics.filter((music, i) => {
                return !played.includes(i);
            }).cycle(musicIndex, "next", random);

            if(!next){
                dispatch(musicsActions.play(-1));
                next = musics.cycle(musicIndex, "next", random);
            }

            // increment
            setMusicCount(musicCount + 1);
        }

        // force clear spots
        setSpot(false);

        // play
        setMusic(next);
    }

    const prevMusic = () => {
        // get prev music
        const prev = musics.cycle(musicIndex, "prev");

        // play
        setMusic(prev);
    }

    const login = async (user) => {
        // clear user
        await setSigned(user);

        // redirect
        history.push('/client/home');
    }

    const logout = async () => {
        // clear user
        await setSigned({});
        await setPlaylist({});
        // await setMusics([]);

        // redirect
        // setTimeout(() => history.push('/signin'), 1500);
        window.top.location.href = '/signin';
    }

    const state = {
        signed, setSigned,
        playlist, setPlaylist,
        music, setMusic,
        player, setPlayer,
        playerStatus, setPlayerStatus,
        random, setRandom,
        repeat, setRepeat,
        prevMusic,
        nextMusic,
        login,
        logout
    };

    return (
        <MainContext.Provider value={state}>
            {children}
        </MainContext.Provider>
    );
};

export default MainContext;