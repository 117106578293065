import api from "../../services/api"
import {PLAYLISTS} from "../../constants";

export const playlistsActions = {
    _get: getPlaylists,
    _post: postPlaylist,
    _put: putPlaylist,
    _delete: deletePlaylist,
    entity
};

function getPlaylists(params = {}) {

    const storage = `PLAYLISTS_${params.user_id}`;

    return (dispatch) => {
        // load from local
        let cache = localStorage.getItem(storage) || false;
        if(cache){
            dispatch({
                type: PLAYLISTS.LIST_SUCCESS,
                payload: JSON.parse(cache),
            });
        }

        // loading
        dispatch(loading(PLAYLISTS.LIST));

        // get data
        api
            .get('/playlists', {params})
            .then((res) => {

                localStorage.setItem(storage, JSON.stringify(res.data));

                dispatch({
                    type: PLAYLISTS.LIST_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                dispatch({
                    type: PLAYLISTS.LIST_FAILURE,
                    payload: e,
                })
            });
    }
}

function postPlaylist(data = {}){
    return (dispatch) => {
        // loading
        dispatch(loading(PLAYLISTS.CREATE));

        // get data
        api
            .post('/playlists', data)
            .then((res) => {
                dispatch({
                    type: PLAYLISTS.CREATE_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                dispatch({
                    type: PLAYLISTS.CREATE_FAILURE,
                    payload: e,
                })
            });
    }
}

function putPlaylist(data = {}){
    return (dispatch) => {
        // loading
        dispatch(loading(PLAYLISTS.UPDATE));

        // get data
        api
            .put(`/playlists/${data.id}`, data)
            .then((res) => {
                dispatch({
                    type: PLAYLISTS.UPDATE_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                dispatch({
                    type: PLAYLISTS.UPDATE_FAILURE,
                    payload: e,
                })
            });
    }
}

function deletePlaylist(id){
    return (dispatch) => {
        // loading
        dispatch(loading(PLAYLISTS.DELETE));

        // get data
        api
            .delete(`/playlists/${id}`)
            .then((res) => {
                dispatch({
                    type: PLAYLISTS.DELETE_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((e) => {
                dispatch({
                    type: PLAYLISTS.DELETE_FAILURE,
                    payload: e,
                })
            });
    }
}

function loading(type, loading = true) {
    return {type, payload: loading};
}

function entity(payload){
    return {type: PLAYLISTS.ENTITY, payload};
}