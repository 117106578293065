import React, {useEffect} from "react";
import {Button, Form, Input} from "semantic-ui-react";
import {useForm} from "react-hook-form";
import {musicsActions} from "../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";
import {APP} from "../../constants"

const MusicsEdit = ({user_id, playlist_id, entity}) => {
    const dispatch = useDispatch();
    const save = useSelector(({Musics}) => Musics.save);

    const {register, unregister, handleSubmit, setValue, formState, trigger, reset} = useForm({
        mode: 'onChange',
        defaultValues: {
            id: entity.id || "",
            name: entity.name || "",
            artist: entity.artist || "",
            active: entity.active || "1",
        }
    });

    const closeModal = async () => {
        await dispatch(musicsActions.toggle(false));
    }

    useEffect(() => {
        if (save.success && !save.loading) {
            async function call() {
                await reset();
                await closeModal();
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: `Música ${entity.id ? 'editada' : 'enviada'} com sucesso!`,
                    showConfirmButton: false,
                    timer: 2000,
                    toast: true,
                    backdrop: false
                });
            }

            call();
        }
    }, [save]);

    useEffect(() => {
        register('name', {required: true, minLength: 3});
        register('artist');
        register('active');
    }, [register]);

    useEffect(() => {
        if (!entity.id) {
            return;
        }

        unregister('file');

        setValue('id', entity.id);
        setValue('name', entity.name);
        setValue('artist', entity.artist);
        setValue('active', entity.active);

        trigger();

    }, [entity]);

    const onSubmit = (data) => {
        dispatch(musicsActions._put(data));
    };

    const {isValid} = formState;

    return (
        <Form
            id={"MusicsEdit"}
            onSubmit={handleSubmit(onSubmit)}
            loading={save.loading}
        >
            <input type={'hidden'} name={'id'} defaultValue={entity.id || ""} ref={register()}/>

            <Form.Field
                name={"name"}
                label={"Nome da Música"}
                onChange={async (e, {name, value}) => {
                    await setValue(name, value);
                    await trigger(name);
                }}
                defaultValue={entity.name || ""}
                control={Input}
                autoFocus
            />
            <Form.Field
                name={"artist"}
                label={"Artista"}
                onChange={async (e, {name, value}) => {
                    await setValue(name, value);
                    await trigger(name);
                }}
                defaultValue={entity.artist || ""}
                control={Input}
            />
            <Form.Field>
                <Form.Select
                    fluid
                    name={'active'}
                    label={'Ativo'}
                    options={APP.options.sn}
                    defaultValue={entity.id ? entity.active ? '1' : '0' : '1'}
                    onChange={async (e, {name, value}) => {
                        await setValue('active', value);
                        await trigger(name);
                    }}
                />
            </Form.Field>
            <div
                style={{textAlign: "right"}}
            >
                <Button
                    basic
                    type={"button"}
                    onClick={() => {
                        reset();
                        closeModal();
                    }}
                >
                    Fechar
                </Button>
                <Button
                    primary
                    type={"submit"}
                    disabled={!isValid}
                >
                    Salvar
                </Button>
            </div>
        </Form>
    );
}

export default MusicsEdit;