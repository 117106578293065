import React, {Fragment, useEffect, useState, useCallback} from "react";
import Swal from "sweetalert2";
import {useDispatch, useSelector} from "react-redux";
import {playlistsActions} from "../../redux/actions";
import {MainContainer, MusicContainer, MusicsContainer} from "./style";
import {Button, Icon} from "semantic-ui-react";
import {useHistory, useParams} from "react-router-dom";
import {Title} from "./components/Title";

import PlaylistsModal from "./PlaylistsModal";

import Empty from "../Empty/Empty";
import {hasExpired} from "../../utils";
import {DateTime} from "luxon";

const getDuration = (duration) => {
    const ms = duration * 1000;
    if (isNaN(ms)) {
        return '00:00:00';
    }

    // return time
    return new Date(ms).toISOString().substr(11, 8);
}

const Playlist = ({role, playlist, selectPlaylist, editPlaylist, deletePlaylist}) => {

    const duration = getDuration(playlist.duration);
    const expires = DateTime.fromISO(playlist.expires_in);

    if(role === "user" && hasExpired(playlist.expires_in)){
        return <React.Fragment/>;
    }

    return (
        <MusicContainer onClick={() => selectPlaylist(playlist.id)}>
            <div>
                <Icon
                    name={"list"}
                />
            </div>
            <div>
                <div>{playlist.name}</div>
                <div>
                    <Icon name={"calendar check outline"}/>{expires.toLocaleString() || '00/00/00'}
                    <span
                        style={{
                            paddingLeft: "1rem"
                        }}
                    >
                    <Icon
                        name={"music"}
                    />{playlist.music_count}
                    </span>
                </div>
            </div>
            <div>
                {duration}
            </div>
            {
                role === "admin" && <div>
                    <Button.Group
                        icon
                        vertical
                        inverted
                        basic
                        size={"tiny"}
                    >
                        <Button
                            icon={"pencil"}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                editPlaylist(playlist);
                            }}
                        />
                        <Button
                            icon={"trash"}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                deletePlaylist(playlist.id);
                            }}
                        />
                    </Button.Group>
                </div>
            }
        </MusicContainer>
    );
}

const Main = ({user_id, role, playlists, reload}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);

    const createPlaylist = () => {
        dispatch(playlistsActions.entity({}));
        setShowModal(true);
    }

    const selectPlaylist = (playlist_id) => {
        history.push(`/client/home/${user_id}/${playlist_id}/musics`);
    }

    const editPlaylist = (playlist) => {
        dispatch(playlistsActions.entity(playlist));
        setShowModal(true);
    }

    const deletePlaylist = (playlist_id) => {
        Swal.fire({
            title: 'Atenção!',
            text: 'Deseja realmente excluir esta playlist?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Sim, quero excluir!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(playlistsActions._delete(playlist_id));
            }
        });
    }

    const closeModal = () => {
        dispatch(playlistsActions.entity({}));
        setShowModal(false);
    }

    const goClients = () => {
        history.push('/client/home');
    }

    return (
        <Fragment>
            <div id={"playlistScroll"}>
                {
                    playlists.data.length
                        ? <MusicsContainer>
                            {
                                playlists.data.map((playlist) => {
                                    return (
                                        <Playlist
                                            key={`playlist-${playlist.id}`}
                                            role={role}
                                            playlist={playlist}
                                            selectPlaylist={selectPlaylist}
                                            editPlaylist={editPlaylist}
                                            deletePlaylist={deletePlaylist}
                                        />
                                    )
                                })
                            }
                        </MusicsContainer>
                        : <Empty
                            icon={"list"}
                            title={"Nenhuma playlist encontrada"}
                            message={"Começe agora mesmo criando uma nova Playlist"}
                        />
                }
            </div>
            <div id={"playlistActions"}>
                {
                    role === "admin"
                        ?
                        <>
                            <PlaylistsModal
                                id={0}
                                user_id={user_id}
                                showModal={showModal}
                                closeModal={closeModal}
                            />
                            <Button.Group widths={2}>
                                <Button
                                    basic
                                    inverted
                                    content={"VER CLIENTES"}
                                    onClick={goClients}
                                />
                                <Button
                                    basic
                                    inverted
                                    content={"CRIAR PLAYLIST"}
                                    onClick={() => createPlaylist()}
                                />
                            </Button.Group>
                        </>
                        :
                        <Button
                            fluid
                            basic
                            inverted
                            content={"ATUALIZAR LISTAS"}
                            onClick={reload}
                        />
                }
            </div>
        </Fragment>
    );
}

const Playlists = ({role}) => {
    const dispatch = useDispatch();
    const params = useParams();

    const playlists = useSelector(({Playlists}) => Playlists.results);
    const save = useSelector(({Playlists}) => Playlists.save);
    const [duration, setDuration] = useState("00:00:00");

    const user_id = params.user_id || 0;
    let counter = playlists.data.length;
    let active = undefined;
    if(role === "user"){
        counter = playlists.data.filter((item) => !hasExpired(item.expires_in)).length;
        active = 1;
    }

    const getPlaylists = useCallback(() => {
        if (!parseInt(user_id)) {
            return;
        }
        dispatch(playlistsActions._get({active, user_id}));
    }, [dispatch, user_id, active]);

    useEffect(() => getPlaylists(), [getPlaylists]);

    useEffect(() => {
        if (save.success) {
            getPlaylists();
        }
    }, [getPlaylists, save])

    useEffect(() => {
        async function calcDuration() {

            const totalTime = await playlists.data.reduce((total, value) => {
                return total + value.duration;
            }, 0);

            if (!totalTime) {
                return;
            }

            const duration = new Date(totalTime * 1000).toISOString().substr(11, 8);

            setDuration(duration);
        }

        // noinspection JSIgnoredPromiseFromCall
        calcDuration();

    }, [playlists]);

    return (
        <MainContainer>
            <Title
                loading={playlists.loading}
                counter={counter}
                duration={duration}
                title={"Playlists"}
            />
            {
                user_id
                    ? <Main
                        user_id={user_id}
                        role={role}
                        playlists={playlists}
                        reload={getPlaylists}
                    />
                    : <Empty
                        icon={"user outline"}
                        title={"Nenhum cliente selecionado"}
                        message={"Selecione um cliente para ver as suas playlists"}
                    />
            }

        </MainContainer>
    );
}

export default Playlists;