import {Grid, Header} from "semantic-ui-react";
import styled from "styled-components";

export const MusicsContainer = styled.div`
    > :hover, .active {
        background-color: rgba(255, 255, 255, .3) !important;
    }
`

export const MusicContainer = styled.div`
    display: flex;
    color: #FFF;
    margin-bottom: 1rem;
    align-items: center;
    cursor: pointer;
    
    .music.icon {
        color: rgba(255, 255, 255, .4);
    }
    
    .music.icon.normalized {
        color: #FFF;
    }
    
    > div:nth-child(1) {
        size: 32px !important;
        padding: 24px;
    }
    
    > div:nth-child(2) {
        flex-grow: 1;
        max-width: 100%;
        overflow: hidden;
 
        > div:first-child {
            font-size: 1.2rem;
            font-weight: bold;
            line-height: 2rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;

        }
        
        > div:last-child {
            font-size: .8rem;
        }
    }
    
    > div:nth-child(3) {
        padding: .5rem;
    }
`

export const PlaylistContainer = styled(Grid)`
    height: 100%;
    > div.row {
        height: fit-content;
    }
    
    div#playlistScroll {
        // height: calc(100% - 100px);
        // min-height: calc(100% - 100px);
        overflow: auto;
        padding-top: 0px;
        padding-bottom: 0px;
    }
`

export const StyledHeader = styled(Header)`
    border-bottom: 2px solid rgba(255, 255, 255, .5) !important;
    padding-bottom: .5rem !important;
`

export const MainContainer = styled.div`
    height: inherit;
    min-height: 0;
    display: flex;
    flex-direction: column;
    
    div#playlistScroll {
        height: inherit;
        min-height: 0;
        overflow: auto;
    }
    
    div#playlistActions{
        padding-top: 1rem;
    }
`

export const TitleContainer = styled.div`
    color: rgba(255, 255, 255, .8);
    display: flex;
    height: fit-content;
    min-height: fit-content;
    flex-direction: column;
    padding-bottom: 1rem;
    
    div#mainCounter {
        display: flex;
        width: 100%;
        padding-bottom: 1rem;
        > div:first-child {
            flex-grow: 1;
        }
    }
`;

export const MusicsUploadContainer = styled.div`
    height: 340px !important;
    display: flex;
    flex-direction: column;
    > div:first-child {
        height: 100%;
        overflow: auto;
    }
    > div:last-child{
        padding-top: 1.5rem;
    }
    
    div.ui.green.tiny.indicating.progress{
        margin: 0px;
        > div {
            min-width: 0px !important;
        }
    }
`;