import React from 'react';
import { Route, Switch } from 'react-router-dom';

import {MainProvider} from "../../contexts/MainContext";

import Home from "./Home/Home";

const ClientRoutes = () => {
    return (
        <MainProvider>
            <Switch>
                <Route path="/client/home/:user_id/:type" exact component={Home} />
                <Route path="/client/home/:user_id/:playlist_id/:type" exact component={Home} />
                <Route component={Home}/>
            </Switch>
        </MainProvider>
    );
}

export default ClientRoutes;
