export const PLAYLISTS = {
  ENTITY: "PLAYLISTS_ENTITY",

  LIST: "PLAYLISTS_LIST",
  LIST_SUCCESS: "PLAYLISTS_LIST_SUCCESS",
  LIST_FAILURE: "PLAYLISTS_LIST_FAILURE",

  CREATE: "PLAYLISTS_CREATE",
  CREATE_SUCCESS: "PLAYLISTS_CREATE_SUCCESS",
  CREATE_FAILURE: "PLAYLISTS_CREATE_FAILURE",

  READ: "PLAYLISTS_READ",
  READ_SUCCESS: "PLAYLISTS_READ_SUCCESS",
  READ_FAILURE: "PLAYLISTS_READ_FAILURE",

  UPDATE: "PLAYLISTS_UPDATE",
  UPDATE_SUCCESS: "PLAYLISTS_UPDATE_SUCCESS",
  UPDATE_FAILURE: "PLAYLISTS_UPDATE_FAILURE",

  DELETE: "PLAYLISTS_DELETE",
  DELETE_SUCCESS: "PLAYLISTS_DELETE_SUCCESS",
  DELETE_FAILURE: "PLAYLISTS_DELETE_FAILURE",
};